import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";

import Layout from "../../layout/Layout";
import { isAuth, signout } from '../../utility/Helper';

const AdminDashboard = () => {
    const [userStatus, setUserStatus] = useState([]);
    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        // Axios({
        //     method: 'GET',
        //     url: `${configURL.adminDashnboardInfo}`
        // })
        //     .then(response => {
        //         setUserStatus(response.data.user_status);
        //         console.log(response.data.user_status);
        //     })
        //     .catch(e => {
        //         console.log(e);
        //     });
    };
    return (<Layout>
        <div className="box box-info">
            <div className="box-header">
                <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-dashboard"></i></span> Dashboard</h3>
            </div>
            <div className="box-body">
                <h4>Hello, {isAuth().name}</h4>
                <p>Welcome to Beyond Networking</p>
            </div>
        </div>

    </Layout>)
}

export default AdminDashboard;