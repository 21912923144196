import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";
import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import useSearch from "../../utility/useSearch";

const Point = ({ history }) => {

    const [rows, setRows] = useState([]);

    const [loader, setLoader] = useState(true);
    const [search, Searchable] = useSearch();

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    useEffect(() => {
        retrieveData({ selectedPageNumber: 1, search: '' });
    }, []);

    const retrieveData = ({ selectedPageNumber = 1, search = '' }) => {
        setLoader(true);
        Axios({
            method: 'POST',
            url: `${configURL.Point.Index}?current_page=${selectedPageNumber}&search=${search}`
        }).then(response => {
            setRows(response.data.data);
            setPagination(response.data.pagination);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    const toTitleCase = (text) => {
        if (text.length < 1) {
            return '';
        }

        return text.split('_').map((ele) => {
            return ele.charAt(0).toUpperCase() + ele.substr(1).toLowerCase();
        }).join(' ');
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-primary">
                <ToastContainer />
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat">
                        <i className="fa fa-area-chart"></i></span> Points
                    </h3>
                </div>
                {Searchable((search) => retrieveData({ search }))}
                <div className="box-body table-responsive no-padding">
                    {rows.length > 0 && <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.No.</th>
                                <th>Name</th>
                                <th>Activity</th>
                                <th>Score</th>
                            </tr>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <td>{AutoSerialNumber(index, pagination)}</td>
                                    <td>{row.name}</td>
                                    <td>{toTitleCase(row.for_which)}</td>
                                    <td>{parseInt(row.score)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                    {rows.length < 1 && <div style={{ textAlign: 'center' }}>
                        No data found!
                    </div>}
                </div>

                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            retrieveData({ selectedPageNumber, search });
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default Point;