import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";
import ScreenLoader from "../../layout/ScreenLoader";
import TimeAgo from "../../utility/TimeAgo"
import { useForm } from 'react-hook-form';

const GivenTestimonial = (props) => {

    const { register, handleSubmit, setValue, reset } = useForm();

    const query = new URLSearchParams(window.location.search);
    const refMessageID = query.get('refmsgid');
    // const refMessageID = null;

    const [testimonials, setTestimonials] = useState([]);

    const [loader, setLoader] = useState(false);

    useEffect(function () {
        retrieveData();
    }, []);

    const retrieveData = () => {
        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.givenTestimonials}`
        }).then((response) => {
            setTestimonials(response.data.data.reverse());
        }).catch((error) => {
            setTestimonials([]);
        }).finally(() => {
            setLoader(false);
        })
    }

    return (
        <>
            {loader && <ScreenLoader />}
            <ToastContainer />

            <ul className="timeline">
                {testimonials.map((testimonial, index) => (
                    <li key={testimonial.id}>
                        <i className={testimonial.approved_date ? 'fa fa-commenting bg-green' : 'fa fa-commenting bg-yellow'}></i>
                        <div className="timeline-item">
                            <span className="time">
                                <i className="fa fa-calender"></i> {TimeAgo(testimonial.createdAt)}
                            </span>
                            <h3 className="timeline-header">
                                {testimonial.to_user_name} <small>{!testimonial.approved_date && ' [Pending for acceptance]' }</small>
                                </h3>
                            <div className="timeline-body">
                                {testimonial.testimonial.split('\n').map((str, index2) => <p key={index2}>{str}</p>)}
                            </div>
                        </div>
                    </li>
                ))}

            </ul>
            {testimonials.length < 1 && <div className="text-center">
                No testimonial found!
            </div>}
        </>
    )
}

export default GivenTestimonial;