import React from 'react';
import { isAuth, signout } from '../utility/Helper';
import createBrowserHistory from 'history/createBrowserHistory';
import TimeAgo from "../utility/TimeAgo"
import { Link } from 'react-router-dom';
const history = createBrowserHistory();
const Header = () => {
  return (<header className="main-header">
    <a href="index2.html" className="logo">
      <span className="logo-mini"><b>B</b>N</span>
      <span className="logo-lg"><b>BeyondNetworking</b></span>
    </a>
    <nav className="navbar navbar-static-top" role="navigation">
      <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
        <span className="sr-only">Toggle navigation</span>
      </a>
      <div className="navbar-custom-menu">
        <ul className="nav navbar-nav">
          {/* <li className="dropdown messages-menu">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
              <i className="fa fa-envelope-o"></i>
              <span className="label label-success">4</span>
            </a>
            <ul className="dropdown-menu">
              <li className="header">You have 4 messages</li>
              <li>
                <ul className="menu">
                  <li>
                    <a href="#">
                      <div className="pull-left">
                        <img src="dist/img/user2-160x160.jpg" className="img-circle" alt="User Image"></img>
                      </div>
                      <h4>
                        Support Team
                        <small><i className="fa fa-clock-o"></i> 5 mins</small>
                      </h4>
                      <p>Why not buy a new awesome theme?</p>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="footer"><a href="#">See All Messages</a></li>
            </ul>
          </li> */}

          {isAuth() && (
            <li className="dropdown user user-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <img src="/dist/img/user.png" className="user-image" alt="User Image"></img>
                <span className="hidden-xs">{isAuth().name}</span>
              </a>
              <ul className="dropdown-menu">
                <li className="user-header">
                  <img src="/dist/img/user.png" className="img-circle" alt="User Image"></img>

                  <p>
                    <strong>{isAuth().name}</strong> - {isAuth().role}
                    <small>Member since : {TimeAgo(isAuth().createdAt)}</small>
                  </p>
                </li>
                <li className="user-footer">
                  <div className="pull-left">
                    <Link to='/profile' className='btn' style={{color:'#FFFFFF', backgroundColor:'#07bc0c'}}>Profile</Link>
                  </div>
                  <div className="pull-right">
                    <a href="#" onClick={() => {
                      signout(() => {
                        history.push('/signin');
                      })
                    }} className="btn" style={{color:'#FFFFFF', backgroundColor:'#e74c3c'}}>Sign out</a>
                  </div>
                </li>
              </ul>
            </li>
          )}

        </ul>
      </div>
    </nav>
  </header>)
}

export default Header;