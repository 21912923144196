import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";

import { useForm } from 'react-hook-form';

const DetailsRoleAccessMatrix = ({ history }) => {
    const { register, handleSubmit, setValue } = useForm();
    const [role, setRole] = useState([]);
    const [roleMatrixs, setRoleMatrixs] = useState([]);
    const urlParams = useParams();

    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        Axios({
            method: 'GET',
            url: `${configURL.detailRoleAccessMatrix}/${urlParams.id}`
        })
            .then(response => {
                setRole(response.data.role);
                setRoleMatrixs(response.data.data);
                Object.keys(response.data.data).forEach(key => {
                    // console.log(key, response.data.data[key]);
                    // console.log(key);
                    setValue(key, response.data[key]);
                });

                // console.log(response);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleUpdate = (data) => {
        console.log(data);
        let lFormData = {};
        lFormData['code'] = data.code;
        lFormData['display_title'] = data.display_title;
        lFormData['description'] = data.description;
        lFormData['active'] = data.active;
        Axios({
            method: 'PUT',
            url: `${configURL.roles}/${urlParams.id}`,
            data: lFormData
        })
            .then(response => {
                console.log("UPDATE ROLES RESPONSE", response);
                toast.success(response.data.message);
                history.push("/roles");
            })
            .catch(error => {
                console.log('UPDATE ROLES ERROR', error.response.data.error);
                toast.error(error.response.data.error);
            });
    };

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleUpdate)} className="form-horizontal">
            <div className="box-body table-responsive no-padding">
                <table className="table table-hover">
                    <tbody>
                        <tr>
                            <th>Sr.no.</th>
                            <th>Menu</th>
                            <th>Read</th>
                            <th>Write</th>
                            <th>Update</th>
                            <th>Delete</th>
                        </tr>
                        {roleMatrixs &&
                            roleMatrixs.map((rm, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{rm.name}</td>
                                    <td>{true}<input type="checkbox" {...register('read')} /></td>
                                    <td><input type="checkbox" {...register('write')} /></td>
                                    <td><input type="checkbox" {...register('update')} /></td>
                                    <td><input type="checkbox" {...register('delete')} /></td>
                                </tr>
                            ))}

                    </tbody>
                </table>
            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout><div className="box box-danger">
            <div className="box-header with-border">
                <ToastContainer />
                <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-cubes"></i></span> Detail Role Access Matrix [ {role.display_title} ]</h3>
                <div class="pull-right box-tools">
                    <Link to={`/roles`} className="btn btn-primary btn-sm btn-flat"><i className="fa fa-arrow-left" title="Back"></i> Back to Roles</Link>
                </div>
            </div>
            {lForm()}
        </div></Layout>
    )
}

export default DetailsRoleAccessMatrix;