import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";

import { useForm } from 'react-hook-form';

import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
import ScreenLoader from "../../layout/ScreenLoader";

const ViewMeeting = ({ history }) => {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [row, setRow] = useState([]);
    const urlParams = useParams();
    const [loader, setLoader] = useState(true);
    const [meetingCategories, setMeetingCategories] = useState([]);

    useEffect(() => {
        setMeetingCategories([
            { id: 1, display_title: 'PUBLIC' },
            { id: 2, display_title: 'PRIVATE' },
            { id: 3, display_title: 'CHAPTER MEETING' },
        ]);

        // Axios({
        //     method: 'GET',
        //     url: `${configURL.zones}?per_page=10000`
        // }).then(response => {
        //     // setMeetingCategories(response.data.data);
        // }).catch(e => {
        //     console.log(e);
        // });

        Axios({
            method: 'GET',
            url: `${configURL.Meeting.View}${urlParams.id}`
        }).then(response => {
            response.data.event_date = response.data.event_date.substring(0, 16)
            setRow(response.data);
            Object.keys(response.data).forEach(key => {
                setValue(key, response.data[key]);
            });
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    }, []);

    const handleUpdate = (data) => {
        console.log(data);
        let lFormData = {};
        lFormData['name'] = data.name;
        lFormData['event_date'] = data.event_date;
        lFormData['description'] = data.description;
        lFormData['display_title'] = data.display_title;
        lFormData['event_location'] = data.event_location;
        lFormData['meeting_category_id'] = data.meeting_category_id;
        lFormData['active'] = data.active;

        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.Meeting.Update}${urlParams.id}`,
            data: lFormData
        }).then(response => {
            toast.success(response.data.message);
            history.push("/meetings");
        }).catch(error => {
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        });
    };

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleUpdate)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="name" type="text" {...register('name', { required: 'required!' })} className="form-control" placeholder="Name" />
                        <InputError errors={errors} field={'name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Display Title <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('display_title', { required: 'required!' })} className="form-control" placeholder="Display Title" name="display_title" />
                        <InputError errors={errors} field={'display_title'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Meeting Category <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('meeting_category_id', { required: 'required!' })}>
                            {meetingCategories && meetingCategories.map((meetingCategory) => (
                                <option key={meetingCategory.id} value={meetingCategory.id}>
                                    {meetingCategory.display_title}
                                </option>
                            ))}
                        </select>
                        <InputError errors={errors} field={'meeting_category_id'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Description</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" {...register('description')} rows="3" name="description" placeholder="Description"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Event Date <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="datetime-local" {...register('event_date', { required: 'required!' })} className="form-control" placeholder="Display Title" name="event_date" />
                        <InputError errors={errors} field={'event_date'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Event Location <RequiredStar /></label>
                    <div className="col-sm-10">
                        <textarea className="form-control" {...register('event_location', { required: 'required!' })} rows="3" name="event_location" placeholder="Event location"></textarea>
                        <InputError errors={errors} field={'event_location'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Active</label>
                    <div className="col-sm-10">
                        <div className="checkbox">
                            <label>
                                <input type="checkbox" {...register('active')} />
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-success btn-block btn-flat">Submit</button>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-primary">
                <div className="box-header with-border">
                    <ToastContainer />
                    <h3 className="box-title"><span className="btn btn-primary btn-xs btn-flat">
                        <i className="fa fa-area-chart"></i></span> Meeting Details
                    </h3>
                    <div className="pull-right box-tools">
                        <Link to={`/meetings`} className="btn btn-primary btn-sm btn-flat">
                            <i className="fa fa-arrow-left" title="Back"></i> Back to Meetings
                        </Link>
                    </div>
                </div>
                {lForm()}
            </div>
        </Layout>
    )
}

export default ViewMeeting;