import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import APIs from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";

import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import useSearch from "../../utility/useSearch";
import { useForm } from "react-hook-form";

const AddMeetingMembers = () => {

    const URLParams = useParams();
    const [meeting, setMeeting] = useState({});
    const [meetingMembers, setMeetingMembers] = useState([]);
    const [loader, setLoader] = useState(true);
    const [search, Searchable] = useSearch();
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [memberSelected, setMemberSelected] = useState([]);

    const { register, handleSubmit } = useForm();

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    useEffect(() => {
        retrieveDetails();
    }, []);

    const retrieveDetails = () => {
        setLoader(true);

        Promise.all([
            // Meeting
            Axios({
                method: 'GET',
                url: `${APIs.Meeting.View}${URLParams.id}`
            }),

            // Meeting Members
            Axios({
                method: 'POST',
                url: `${APIs.Meeting.Members.Index}`,
                data: {
                    meeting_id: URLParams.id
                }
            })
        ]).then(
            (responses) => {
                setMeeting(responses[0].data);

                setMeetingMembers(responses[1].data.data);
                setPagination(responses[1].data.pagination);
            },
            (error) => {
                setMeeting([]);
                setMeetingMembers([]);
            }
        ).finally(() => {
            setLoader(false);
        });
    }

    const handleSearch = function (search, selectedPageNumber = 1) {
        setLoader(true);

        Axios({
            url: `${APIs.Meeting.Members.Index}?current_page=${selectedPageNumber}&search=${search}`,
            method: 'POST',
            data: {
                meeting_id: URLParams.id
            }
        }).then((response) => {
            setMeetingMembers(response.data.data);
            setPagination(response.data.pagination);
        }).catch((e) => {
            setMeetingMembers([]);
        }).finally(() => {
            setLoader(false);
        });
    }

    const handleInvite = (member_id) => (event) => {
        setLoader(true);

        Axios({
            method: 'POST',
            url: `${APIs.Meeting.Members.Invite}`,
            data: {
                meeting_id: URLParams.id,
                members: [member_id]
            }
        }).then(
            (response) => {
                toast.success('Member invited!');
            },
            (error) => {
                toast.error('Something went wrong!');
            }
        ).finally(() => {
            handleSearch(search, currentPageNumber);
        });
    }

    const handleRemove = (invitation_id) => (event) => {

        if (window.confirm('Are you sure want to remove from invitation?') == false) {
            return;
        }

        setLoader(true);

        Axios({
            method: 'DELETE',
            url: `${APIs.Meeting.Members.Delete}${invitation_id}`
        }).then(
            (response) => {
                toast.success('Member removed!');
            },
            (error) => {
                toast.error('Something went wrong!');
            }
        ).finally(() => {
            handleSearch(search, currentPageNumber);
        });
    }

    const handleSelectMember = (member_id) => (event) => {
        let selected = [...memberSelected];
        const index = selected.indexOf(member_id);

        if (index > -1) {
            selected.splice(index, 1);
            setMemberSelected(selected);
            return;
        }

        if (index === -1) {
            selected.push(member_id);
            setMemberSelected(selected);
            return;
        }
    }

    const handleMassInvite = () => (event) => {
        setLoader(true);

        Axios({
            method: 'POST',
            url: `${APIs.Meeting.Members.Invite}`,
            data: {
                meeting_id: URLParams.id,
                members: memberSelected
            }
        }).then(
            (response) => {
                toast.success('Selected member(s) are invited!');
                setMemberSelected([]);
            },
            (error) => {
                toast.error('Something went wrong!');
            }
        ).finally(() => {
            handleSearch(search, currentPageNumber);
        });
    }

    const handleSelectAll = () => (event) => {
        if (!meetingMembers) {
            return;
        }

        let remainingSelection = [];
        meetingMembers.forEach((e) => {
            if (!e.invitation_id) {
                remainingSelection.push(e.member_id)
            }
        });

        if (remainingSelection.length < 1) {
            toast.error('Member(s) of this page is already invited!');
            return;
        }

        let selected = [...memberSelected, ...remainingSelection];

        // select only unique -> using Set
        setMemberSelected([...new Set(selected)]);
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header">
                    <ToastContainer />
                    <h3 className="box-title">
                        <span className="btn btn-danger btn-xs btn-flat">
                            <i className="fa fa-users"></i>
                        </span> Invite members to meeting : <strong>{meeting.name}</strong>
                    </h3>
                    <div className="pull-right box-tools">
                        <Link to={`/meetings`} className="btn btn-danger btn-sm btn-flat">
                            <i className="fa fa-arrow-left" title="Back"></i> Back to Meetings
                        </Link>
                    </div>
                </div>
                {Searchable((search) => handleSearch(search))}
                <div style={{ padding: '0.75em' }}>
                    <button
                        title="Clear Selection"
                        className="btn btn-success btn-xs"
                        disabled={memberSelected.length < 1}
                        onClick={handleMassInvite()}
                    ><i className="fa fa-user-plus"></i>&nbsp; Invite Selected ({memberSelected.length})</button>&nbsp;
                    {memberSelected.length > 0 &&
                        <button
                            title="invite"
                            className="btn btn-danger btn-xs"
                            onClick={() => setMemberSelected([])}
                        >Clear Selection</button>
                    }
                </div>
                {meetingMembers.length > 0 && <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>
                                    <button
                                        title="Select All"
                                        className="btn btn-default btn-xs"
                                        onClick={handleSelectAll()}
                                    >Select All</button>
                                </th>
                                <th>Sr.no.</th>
                                <th>User</th>
                                <th>Chapter</th>
                                <th>Zone</th>
                                <th>Mobile</th>
                                <th>Action</th>
                            </tr>
                            {meetingMembers.map((member, index) => (
                                <tr key={index}>
                                    <td>
                                        <input type={'checkbox'} disabled={member.invitation_id} onChange={handleSelectMember(member.member_id)} checked={memberSelected.indexOf(member.member_id) > -1} />
                                    </td>
                                    <td>{AutoSerialNumber(index, pagination)}</td>
                                    <td>{member.user_name}</td>
                                    <td>{member.chapter}</td>
                                    <td>{member.zone}</td>
                                    <td>{member.mobile_number}</td>
                                    <td>
                                        {!member.invitation_id && <button
                                            title="invite"
                                            className="btn btn-success btn-xs"
                                            onClick={handleInvite(member.member_id)}
                                        ><i className="fa fa-user-plus"></i>&nbsp; Invite</button>}
                                        {member.invitation_id &&
                                            <Link to={`/meetings/members/edit/${URLParams.id}/${member.invitation_id}`} className="btn btn-primary btn-xs btn-flat">
                                                <i className="fa fa-list"></i>&nbsp; Update
                                            </Link>
                                        }
                                        &nbsp; {member.invitation_id && <button
                                            title="delete"
                                            className="btn btn-danger btn-xs btn-flat"
                                            onClick={handleRemove(member.invitation_id)}
                                        ><i className="fa fa-user-times"></i>&nbsp; Remove</button>}
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>}

                {meetingMembers.length < 1 && <div className="text-center">
                    No data found!
                </div>}

                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            setCurrentPageNumber(selectedPageNumber);
                            handleSearch(search, selectedPageNumber);
                        }}
                    />
                </div>
            </div></Layout >
    )
}

export default AddMeetingMembers;