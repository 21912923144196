import React from "react";

const Status = {
    Active: () => {
        return <i class="fa fa-fw fa-check text-green"></i>;
    },

    Inactive: () => {
        return <i class="fa fa-fw fa-close text-red"></i>;
    },

    Render: ({ status }) => {
        if (status) {
            return <Status.Active />;
        }

        if (!status) {
            return <Status.Inactive />;
        }

        return <>{status}</>;
    }
}

export default Status;