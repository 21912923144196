import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import HeaderExternal from './HeaderExternal';
import FoolterExternal from './FooterExternal';
// import { isAuth } from "../utility/Helper";
const LayoutExternal = ({ children }) => {
    return (<div className="wrapper">
        <HeaderExternal></HeaderExternal>
        <div className="content-wrapper">
            <section className="content container-fluid">{children}</section>
        </div>
        <FoolterExternal></FoolterExternal>
    </div>)
}

export default LayoutExternal;
