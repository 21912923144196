import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";

import { useForm } from 'react-hook-form';

import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
import ScreenLoader from "../../layout/ScreenLoader";

const CreateChapter = ({ history }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const urlParams = useParams();
    const [loader, setLoader] = useState(false);

    const [zone, setZone] = useState([]);
    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        Axios({
            method: 'GET',
            url: `${configURL.zones}/${urlParams.zone_id}`
        })
            .then(response => {
                setZone(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };



    const handleCreate = (data) => {
        console.log(data);
        let lFormData = {};
        lFormData['name'] = data.name;
        lFormData['zone_id'] = urlParams.zone_id;
        lFormData['max_allowed_members'] = data.max_allowed_members;
        lFormData['display_title'] = data.display_title;
        lFormData['active'] = data.active;

        setLoader(true);
        Axios({
            method: 'POST',
            url: `${configURL.createChapter}`,
            data: lFormData
        })
            .then(response => {
                console.log("CREATE Chapter RESPONSE", response);
                toast.success(response.data.message);
                history.push(`/chapters/${urlParams.zone_id}`);
            })
            .catch(error => {
                console.log('CREATE Chapter ERROR', error.response.data.error);
                toast.error(error.response.data.error);
            }).finally(() => {
                setLoader(false);
            });
    };

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleCreate)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="name" type="text" {...register('name', { required: 'required!' })} className="form-control" placeholder="Name" />
                        <InputError errors={errors} field={'name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Display Title <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('display_title', { required: 'required!' })} className="form-control" placeholder="Display Title" name="display_title" />
                        <InputError errors={errors} field={'display_title'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Max allowed members <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="number" {...register('max_allowed_members', { required: 'required!' })} className="form-control" placeholder="Max allowed members" name="max_allowed_members" />
                        <InputError errors={errors} field={'max_allowed_members'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Zone <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('zone_id')} className="form-control" placeholder="Zone" name="zone_id" value={zone.display_title} disabled />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Active</label>
                    <div className="col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" {...register('active')} />
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <ToastContainer />
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-columns"></i></span> Add Chapter</h3>
                    <div class="pull-right box-tools">
                        <Link to={`/chapters/${zone.id}`} className="btn btn-primary btn-sm btn-flat"><i className="fa fa-arrow-left" title="Back"></i> Back to Chapters</Link>
                    </div>
                </div>
                {lForm()}
            </div>
        </Layout>
    )
}

export default CreateChapter;