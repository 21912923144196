import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";

import { useForm } from 'react-hook-form';
import ScreenLoader from "../../layout/ScreenLoader";
import Pagination from "../../utility/pagination/Pagination";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import RequiredStar from "../../utility/RequiredStar";

const AddNetwork = () => {

    const [loader, setLoader] = useState(true);
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    const [noRecordFoundMessage, setNRFM] = useState('');
    const [zones, setZones] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [businessCategories, setBusinessCategories] = useState([]);

    const [members, setMembers] = useState([]);
    const [params, setParams] = useState({});

    const { register, handleSubmit } = useForm();

    const {
        register: meetingRegister,
        handleSubmit: meetingHandleSubmit,
        setValue: meetingSetValue,
        formState: { errors: meetingErrors },
        reset: meetingReset
    } = useForm();

    const [meetingPopupFields, setMeetingPopupFields] = useState({
        meeting_type: '',
        title: '',
        user_id: '',
        enabledAmount: false,
    });

    useEffect(() => {
        retrieveData();
    }, []);

    const clearAllFilter = () => {
        setPagination({
            total: 0,
            per_page: 10,
            current_page: 1
        })
        setParams([])
        setChapters([])
        setMembers([])
    }

    const retrieveData = () => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.zones}?per_page=10000`
        }).then(response => {
            setZones(response.data.data);
        }).catch(e => {
            console.log(e);
        }).finally(function () {
            setLoader(false);
        });

        Axios({
            method: 'GET',
            url: `${configURL.businessCategories}?per_page=10000`
        }).then(response => {
            setBusinessCategories(response.data.data);
        }).catch(e => {
            console.log(e);
        });
    };

    const search = function (params, selectedPageNumber = 1) {
        setLoader(true);
        setNRFM('No data found!');
        setParams({});

        let search_field = params.search_field || '';

        Axios({
            method: 'POST',
            data: params,
            url: `${configURL.members}?current_page=${selectedPageNumber}&search=${search_field}&ignore_my_network=1`
        }).then(response => {
            setMembers(response.data.data);
            setPagination(response.data.pagination);
            setParams(params);
        }).catch(e => {
            setMembers([]);
        }).finally(() => {
            setLoader(false);
        });
    }

    const handleSearch = (params) => {
        search(params);
    };

    const handleChangeZone = () => (event) => {

        if (!event.target.value) {
            setChapters([]);
            return;
        }
        setLoader(true);

        Axios({
            method: 'GET',
            url: `${configURL.getChaptersByzone}/${event.target.value}`
        }).then(response => {
            setChapters(response.data.data);
        }).catch(e => {
            console.log(e);
        }).finally(function () {
            setLoader(false);
        });
    }

    const handleInvite = (UserID, index) => (event) => {

        const tempMembers = [...members];

        if (!tempMembers[index].invited === true) {
            setLoader(true);
            Axios({
                method: 'POST',
                data: {
                    to_user_id: UserID
                },
                url: `${configURL.sendNetworkRequest}`
            }).then(response => {
                toast.success(response.data.message);
                tempMembers[index].invited = !tempMembers[index].invited;
                setMembers(tempMembers);
            }).finally(function () {
                setLoader(false);
            });
        }

        if (!tempMembers[index].invited === false) {
            toast.error('Already invited!');
        }
    }

    const setMeeting = (data) => (event) => {
        setMeetingPopupFields({
            meeting_type: data.meeting_type,
            title: data.title,
            user_id: data.user_id,
            enabledAmount: data.enabledAmount
        })

        meetingReset({
            user_name: data.user_name,
            name: '',
            comment: '',
            amount: 0
        })
    }

    const handleMeeting = (formdata) => {
        const currentUser = JSON.parse(localStorage.getItem('user'))
        const currentUserID = currentUser.id;

        let API, data;

        if (meetingPopupFields.meeting_type === 'ONETOONE') {
            API = configURL.memberMeeting.OneToOne.Create
            data = {
                from_user_id: currentUserID,
                to_user_id: meetingPopupFields.user_id,
                name: formdata.name,
                comment: formdata.comment
            }
        }

        if (meetingPopupFields.meeting_type === 'REFERENCE') {
            API = configURL.memberMeeting.Reference.Create
            data = {
                from_user_id: currentUserID,
                to_user_id: meetingPopupFields.user_id,
                name: formdata.name,
                comment: formdata.comment
            }
        }

        if (meetingPopupFields.meeting_type === 'BUSINESSCLOSE') {
            API = configURL.memberMeeting.BusinessClose.Create
            data = {
                from_user_id: currentUserID,
                to_user_id: meetingPopupFields.user_id,
                name: formdata.name,
                comment: formdata.comment,
                amount: formdata.amount,
            }
        }

        setLoader(true);

        Axios({
            method: 'POST',
            url: API,
            data: data
        }).then(response => {
            toast.success('Done!');
            document.getElementById('closeNetworkMeetingPopup').click();
        }).catch(e => {
            toast.error('Error!');
        }).finally(function () {
            setLoader(false);
        });
    }

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleSearch)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <div className="col-sm-2">
                        <select placeholder="select zone here" className="form-control" {...register('zone_id')} onChange={handleChangeZone()}>
                            <option value="" selected>Select zone</option>
                            {zones && zones.map((zone, index) => (
                                <option key={index} value={zone.id}>
                                    {zone.display_title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <select className="form-control" {...register('chapter_id')}>
                            <option value="" selected>Select chapter</option>
                            {chapters && chapters.map((chapter, index) => (
                                <option key={index} value={chapter.id}>
                                    {chapter.display_title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-2">
                        <select className="form-control" {...register('business_category_id')}>
                            <option value="" selected>Select business category</option>
                            {businessCategories && businessCategories.map((businessCategory, index) => (
                                <option key={index} value={businessCategory.id}>
                                    {businessCategory.display_title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <input type="text" {...register('search_field')} className="form-control" placeholder="search by name, email, mobile, city, top selling product..." name="search_field" />
                    </div>
                    <div className="col-sm-2">
                        <div className="pull-left">
                            <button type="reset" onClick={clearAllFilter} className="btn btn-muted btn-block btn-flat">Reset</button>
                        </div>
                        <div className="pull-left">
                            <button type="submit" className="btn btn-success btn-block btn-flat">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <ToastContainer />
                    <h3 className="box-title">
                        <span className="btn btn-danger btn-xs btn-flat">
                            <i className="fa fa-users"></i>
                        </span> Find Member
                    </h3>
                    <div className="pull-right box-tools">
                        <Link to={`/networks`} className="btn btn-danger btn-sm btn-flat">
                            <i className="fa fa-arrow-left" title="Back"></i> Back to Networks
                        </Link>
                    </div>
                </div>
                {lForm()}
            </div>
            {members.length > 0 && <div className="box box-primary">
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-primary btn-xs btn-flat">
                        <i className="fa fa-users"></i></span> Members
                    </h3>
                </div>
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Name</th>
                                <th>Business Name</th>
                                <th>Mobile</th>
                                <th>City</th>
                                <th>Top Selling Product/Service</th>
                                <th>Actions</th>
                                <th>Meetings</th>
                            </tr>
                            {members && members.map((member, index) => (
                                <tr key={index}>
                                    <td>{AutoSerialNumber(index, pagination)}</td>
                                    <td>{member.user_name}</td>
                                    <td>{member.business_name}</td>
                                    <td>{member.mobile_number}</td>
                                    <td>{member.city}</td>
                                    <td>{member.top_selling_product_service}</td>
                                    <td style={{ width: '160px' }}>
                                        <button title="invite" disabled={member.invited} className="btn btn-success btn-xs btn-flat" onClick={handleInvite(member.user_id, index)}>
                                            {!member.invited
                                                ? <i className="fa fa-user-plus"></i>
                                                : <i className="fa fa-check"></i>}
                                        </button>
                                        &nbsp;| <Link title="profile" to={`/view-member/${member.user_id}`} className="btn btn-primary btn-xs btn-flat">
                                            <i className="fa fa-address-card"></i>
                                        </Link>
                                        &nbsp;| <Link title="message" to={`/messages/${member.user_id}`} className="btn btn-primary btn-xs btn-flat">
                                            <i className="fa fa-envelope"></i>
                                        </Link>
                                        &nbsp;| <Link title="testimonials" to={`/testimonials/${member.user_id}`} className="btn btn-primary btn-xs btn-flat">
                                            <i className="fa fa-commenting"></i>
                                        </Link>
                                    </td>
                                    <td style={{ width: '260px' }}>
                                        <span
                                            data-toggle="modal" data-target="#network-meeting-popup-add-network"
                                            className="btn btn-success btn-xs"
                                            onClick={setMeeting({ meeting_type: 'ONETOONE', title: 'One-to-One Meeting', user_name: member.user_name, user_id: member.user_id, enabledAmount: false })}
                                        >One-to-One</span>
                                        &nbsp;| <span
                                            data-toggle="modal" data-target="#network-meeting-popup-add-network"
                                            className="btn btn-success btn-xs"
                                            onClick={setMeeting({ meeting_type: 'REFERENCE', title: 'Reference Meeting', user_name: member.user_name, user_id: member.user_id, enabledAmount: false })}
                                        >Reference</span>
                                        &nbsp;| <span
                                            data-toggle="modal" data-target="#network-meeting-popup-add-network"
                                            className="btn btn-success btn-xs"
                                            onClick={setMeeting({ meeting_type: 'BUSINESSCLOSE', title: 'Business Close Meeting', user_name: member.user_name, user_id: member.user_id, amount: 0.0, enabledAmount: true })}
                                        > Business Close</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div class="modal fade text-center" id="network-meeting-popup-add-network" style={{ display: 'none' }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header bg-green">
                                <button type="button" id="closeNetworkMeetingPopup" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span></button>
                                <h4 class="modal-title" style={{ textTransform: 'uppercase' }}>{meetingPopupFields.title}</h4>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={meetingHandleSubmit(handleMeeting)}>
                                    <div className="box-body">
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">User</label>
                                            <div className="col-sm-10">
                                                <input type="text" {...meetingRegister('user_name')} disabled className="form-control" name="user_name" placeholder="" />
                                            </div>
                                        </div><br /><br />
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Name <RequiredStar /></label>
                                            <div className="col-sm-10">
                                                <input type="text" {...meetingRegister('name', { required: 'required!' })} className="form-control" name="name" placeholder="" />
                                            </div>
                                        </div><br /><br />
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Comment <RequiredStar /></label>
                                            <div className="col-sm-10">
                                                <textarea className="form-control" {...meetingRegister('comment', { required: 'required!' })} rows="3" name="comment" placeholder=""></textarea>
                                            </div>
                                        </div><br /><br />
                                        {meetingPopupFields.enabledAmount && (<div className="form-group">
                                            <div className="row">
                                            </div><br />
                                            <label className="col-sm-2 control-label">Amount <RequiredStar /></label>
                                            <div className="col-sm-10">
                                                <input type="number" {...meetingRegister('amount', { required: meetingPopupFields.enabledAmount ? 'required!' : false })} className="form-control" name="amount" placeholder="" />
                                            </div>
                                        </div>)}
                                    </div>
                                    <div className="box-footer">
                                        <div className="col-sm-12">
                                            <div className="pull-right">
                                                <button type="submit" className="btn bg-green btn-block btn-flat">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            search(params, selectedPageNumber);
                        }}
                    />
                </div>
            </div>}
            {members.length < 1 && <div className="text-center">
                {noRecordFoundMessage}
            </div>}

        </Layout>
    )
}

export default AddNetwork;