import React from "react";
import { useState } from "react";
import { useForm } from 'react-hook-form';


export default function useSearch() {
    const [search, setSearch] = useState('');
    const { register, handleSubmit } = useForm();

    const handleSearch = function (onSearch) {
        return function (data) {
            setSearch((prev) => {
                onSearch(data.search_field);
                return data.search_field;
            });
        }
    }

    const Searchable = function (onSearch) {
        return (
            <div className="box-body">
                <form onSubmit={handleSubmit(handleSearch(onSearch))} className="form-horizontal">
                    <div className="form-group">
                        <div className="col-md-offset-8 col-md-4">
                            <div class="input-group input-group-sm">
                                <input type="text" {...register('search_field')} className="form-control" placeholder="search here..." name="search_field" />
                                <span class="input-group-btn">
                                    <button type="submit" className="btn btn-danger btn-block btn-flat">Search</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    return [search, Searchable];
}