import React, { useState } from "react";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import { authenticate, isAuth } from "../../utility/Helper";
import { useForm } from "react-hook-form";
import InputError from "../../utility/InputError";

const SignIn = () => {
    const [values, setValues] = useState({
        email: '',
        password: '',
        buttonText: 'Submit'
    });
    const { email, password, buttonText } = values;
    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    }

    const [loader, setLoader] = useState(false);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const resetPasswordPopupForm = () => (
        <form onSubmit={handleSubmit(handleResetPassword)}>
            <div style={{ textAlign: 'left' }}>
                <div className="form-group">
                    <div className="col-sm-9">
                        <input type="email" {...register('rp_email', { required: 'required!' })} defaultValue="" className="form-control" placeholder="enter your email..." name="rp_email" />
                        <InputError errors={errors} field={'rp_email'} />
                    </div>
                    <div className="col-sm-3">
                        <button type="submit" disabled={loader} className="btn btn-primary btn-block btn-flat">Reset</button>
                    </div>
                </div>
            </div>
        </form>
    )

    const handleResetPassword = (data) => {
        setLoader(true)

        axios({
            method: 'POST',
            url: configURL.resetPassword,
            data: { email: data.rp_email }
        }).then((response) => {
            toast.success(response.data.message);
            document.getElementById('closePopup').click();
        }).catch((error) => {
            toast.error(error.response.data.error)
            console.log(error)
        }).finally(() => {
            setLoader(false)
            reset({ rp_email: '' });
        });
    }

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, buttonText: 'Submitting' });
        let lFormData = {};
        lFormData['email'] = email;
        lFormData['password'] = password;
        axios({
            method: 'POST',
            url: `${configURL.signIn}`,
            data: lFormData
        })
            .then(response => {
                console.log("SIGN IN SUCCESS", response);
                authenticate(response, () => {
                    setValues({ ...values, name: '', email: '', password: '', buttonText: 'Submitted' });
                    toast.success(`Welcome ${response.data.user.name}`);
                });

            })
            .catch(err => {
                console.log('SIGN IN ERROR', err.response.data.error);
                setValues({ ...values, buttonText: 'Submit' });
                toast.error(err.response.data.error);
            })
    }

    const signInForm = () => (
        <form method="post">
            <div className="form-group has-feedback">
                <input type="email" onChange={handleChange('email')} value={email} className="form-control" placeholder="Email" />
                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
            </div>
            <div className="form-group has-feedback">
                <input type="password" onChange={handleChange('password')} value={password} className="form-control" placeholder="Password" />
                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
            </div>
            <div style={{ marginBottom: '10px', textAlign: 'left' }}>
                Forgot password ? <span
                    data-toggle="modal" data-target="#reset-password-popup"
                    className="text-info text-bold"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}>Reset</span>
            </div>
            <div className="row">
                <div className="col-xs-4">
                    <button type="submit" onClick={clickSubmit} className="btn btn-primary btn-block btn-flat">{buttonText}</button>
                </div>
            </div>
        </form>
    )

    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '7.5%'
            }}>
                <img src="./dist/img/logo.png" style={{
                    width: "20em"
                }} />
                <div className="row" style={{
                    marginTop: '5%',
                    border: '1px solid #DDD',
                    borderRadius: '5px'
                }}>
                    <div className="col-md-4 text-center">
                        <div className="register-box">
                            <div className="register-box-body">
                                <ToastContainer />
                                {isAuth() ? <Redirect to="/" /> : null}
                                <h4 className="login-box-msg text-center">Sign In</h4>
                                {signInForm()}
                                <div className="social-auth-links text-center">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade text-center" id="reset-password-popup" style={{ display: 'none' }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" id="closePopup" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">x</span></button>
                            <h4 class="modal-title">Forgot password</h4>
                        </div>
                        <div class="modal-body">
                            {resetPasswordPopupForm()}
                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default SignIn;