import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";

import { useForm } from 'react-hook-form';
import ScreenLoader from "../../layout/ScreenLoader";
import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
import ListTestimonial from "../testimonial/ListTestimonial";
import TimeAgo from "../../utility/TimeAgo"

const DetailsMember = ({ history }) => {
    const currentUserHigherRole = Math.min(...JSON.parse(JSON.parse(localStorage.getItem('user')).roles))

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [chapters, setChapters] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [zones, setZones] = useState([]);
    const [businessCategories, setBusinessCategories] = useState([]);
    const [member, setMember] = useState([]);
    const [userStatus, setUserStatus] = useState([]);
    const [values, setValues] = useState({
        user_status_id: ''
    });

    const [loader, setLoader] = useState(true);

    const { user_status_id } = values;

    const urlParams = useParams();
    useEffect(() => {
        // retrieveMembers();
        retrieveData();
    }, []);

    const retrieveMembers = () => {
        Axios({
            method: 'GET',
            url: `${configURL.detailMember}/${urlParams.id}`
        }).then(response => {
            setMember(response.data.data);

            Object.keys(response.data.data).forEach(key => {
                setValue(key, response.data.data[key]);
            });

            setUserStatus(response.data.userStatus);
            setValues({ ...values, user_status_id: response.data.data.user_status_id });

            Axios({
                method: 'GET',
                url: `${configURL.pincodeById}/${response.data.data.pincode_id}`
            }).then(response => {
                setPincodes(response.data.data);
                setCities(response.data.cities);
                setStates(response.data.states);
                setValues({ ...values, pincode: response.data.data.pincode });
            }).catch(e => {
                console.log(e);
            });

        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    const retrieveData = () => {
        Axios({
            method: 'GET',
            url: `${configURL.zones}?per_page=10000`
        }).then(response => {
            setZones(response.data.data);

            Axios({
                method: 'GET',
                url: `${configURL.chapters}?per_page=10000`
            }).then(response => {
                setChapters(response.data.data);

                Axios({
                    method: 'GET',
                    url: `${configURL.businessCategories}?per_page=10000`
                }).then(response => {
                    setBusinessCategories(response.data.data);

                    retrieveMembers();
                }).catch(e => {
                    console.log(e);
                });
            }).catch(e => {
                console.log(e);
            });

        }).catch(e => {
            console.log(e);
        });
    };

    const handleChangeUserStatus = (name) => (event) => {

        setLoader(true);

        setValues({ ...values, user_status_id: event.target.value });

        let lFormData = {};
        lFormData['user_status_id'] = event.target.value;

        Axios({
            method: 'POST',
            url: `${configURL.postChangeStatusMember}/${urlParams.id}`,
            data: lFormData
        }).then(response => {
            toast.success(response.data.message);
            history.push("/members");
        }).catch(error => {
            console.log('STATUS UPDATE MEMBER ERROR', error.response.data.error);
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        });
    }

    const handleChangeZone = (name) => (event) => {
        if (name == 'zone_id') {
            setLoader(true);

            Axios({
                method: 'GET',
                url: `${configURL.getChaptersByzone}/${event.target.value}`
            }).then(response => {
                setChapters(response.data.data);
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false);
            });
        }
    }

    const handlePincode = (name) => (event) => {
        const lPincode = event.target.value;

        if (lPincode.length == 6) {
            setLoader(true);

            Axios({
                method: 'GET',
                url: `${configURL.pincodeGetData}/${lPincode}`
            }).then(response => {
                setPincodes(response.data.data);
                setCities(response.data.cities);
                setStates(response.data.states);
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false);
            });
        }
    }

    const handleUpdateMember = (data) => {
        console.log(data);
        const lPincode = pincodes.find(o => o.id == data.pincode_id);

        let lFormData = {};
        lFormData['chapter_id'] = data.chapter_id;
        lFormData['business_category_ids'] = data.business_category_ids;
        // lFormData['occoupation'] = data.occoupation;
        lFormData['business_name'] = data.business_name;
        lFormData['phone'] = data.phone;
        lFormData['mobile_number'] = data.mobile_number;
        lFormData['website'] = data.website;
        lFormData['discribe_products_services'] = data.discribe_products_services;
        lFormData['top_selling_product_service'] = data.top_selling_product_service;
        lFormData['ideal_connection_for_business'] = data.ideal_connection_for_business;
        lFormData['hobbies_interests'] = data.hobbies_interests;
        lFormData['education'] = data.education;
        lFormData['goal_in_life'] = data.goal_in_life;
        lFormData['belief'] = data.belief;
        lFormData['your_skills'] = data.your_skills;
        lFormData['in_any_idara_khidmat'] = data.in_any_idara_khidmat;
        lFormData['address'] = data.address;
        lFormData['city'] = lPincode ? lPincode.village : null;
        lFormData['state'] = lPincode ? lPincode.state : null;
        lFormData['country'] = lPincode ? lPincode.country : null;
        lFormData['pincode'] = lPincode ? lPincode.pincode: null;
        lFormData['pincode_id'] = lPincode ? lPincode.id : null;
        lFormData['anything_you_would_like_to_say_about_you'] = data.anything_you_would_like_to_say_about_you;

        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.updateMember}/${urlParams.id}`,
            data: lFormData
        }).then(response => {
            console.log("UPDATE MEMBER RESPONSE", response);
            toast.success(response.data.message);
            history.push("/members");
        }).catch(error => {
            console.log('UPDATE MEMBER ERROR', error.response.data.error);
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        });
    };

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleUpdateMember)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Zone <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('zone_id', { required: 'required!' })} defaultValue={member.zone_id} onChange={handleChangeZone('zone_id')} disabled={[1, 2].indexOf(currentUserHigherRole) < 0}>
                            <option value="" disabled selected>Select your option</option>
                            {zones &&
                                zones.map((zone, index) => (
                                    <option key={index} value={zone.id}>
                                        {zone.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'zone_id'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Chapter <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('chapter_id', { required: 'required!' })} defaultValue={member.chapter_id} disabled={[1, 2].indexOf(currentUserHigherRole) < 0}>
                            <option value="" disabled selected>Select your option</option>
                            {chapters &&
                                chapters.map((chapter, index) => (
                                    <option key={index} value={chapter.id}>
                                        {chapter.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'chapter_id'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Category <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('business_category_ids', { required: 'required!' })} defaultValue={member.business_category_ids} disabled={[1, 2].indexOf(currentUserHigherRole) < 0}>
                            <option value="" disabled selected>Select your option</option>
                            {businessCategories &&
                                businessCategories.map((business_cat, index) => (
                                    <option key={index} value={business_cat.id}>
                                        {business_cat.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'business_category_ids'} />
                    </div>
                </div>
                {/* <div className="form-group">
                    <label className="col-sm-2 control-label">Occoupation</label>
                    <div className="col-sm-10">
                        <input name="occoupation" type="text" {...register('occoupation')} defaultValue={member.occoupation} className="form-control" placeholder="Occoupation" />
                    </div>
                </div> */}
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('business_name', { required: 'required!' })} defaultValue={member.business_name} className="form-control" placeholder="Business Name" name="business_name" disabled={[1, 2].indexOf(currentUserHigherRole) < 0} />
                        <InputError errors={errors} field={'business_name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Phone</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('phone')} defaultValue={member.phone} className="form-control" placeholder="Phone" name="phone" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Mobile Number <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('mobile_number', { required: 'required!' })} defaultValue={member.mobile_number} className="form-control" placeholder="Mobile Number" name="mobile_number" />
                        <InputError errors={errors} field={'mobile_number'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Website</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('website')} defaultValue={member.website} className="form-control" placeholder="Website" name="website" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Describe Products Services</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('discribe_products_services')} defaultValue={member.discribe_products_services} className="form-control" name="discribe_products_services" placeholder="Describe Products Services" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Top Selling Product Service</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('top_selling_product_service')} defaultValue={member.top_selling_product_service} className="form-control" name="top_selling_product_service" placeholder="Top Selling Product Service" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Ideal Connection For Business</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('ideal_connection_for_business')} defaultValue={member.ideal_connection_for_business} className="form-control" name="ideal_connection_for_business" placeholder="Ideal Connection For Business" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Hobbies Interests</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('hobbies_interests')} defaultValue={member.hobbies_interests} className="form-control" name="hobbies_interests" placeholder="Hobbies Interests" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Education</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('education')} defaultValue={member.education} className="form-control" name="education" placeholder="Education" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Goal In Life</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('goal_in_life')} defaultValue={member.goal_in_life} className="form-control" name="goal_in_life" placeholder="Goal In Life" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Belief</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('belief')} defaultValue={member.belief} className="form-control" name="belief" placeholder="Belief" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Your Skills</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('your_skills')} defaultValue={member.your_skills} className="form-control" name="your_skills" placeholder="Your Skills" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">In Any Idara Khidmat</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('in_any_idara_khidmat')} defaultValue={member.in_any_idara_khidmat} className="form-control" name="in_any_idara_khidmat" placeholder="In Any Idara Khidmat" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Address</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" {...register('address')} defaultValue={member.address} rows="3" name="address" placeholder="Address"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Pincode</label>
                    <div className="col-sm-3">
                        <div class="input-group">
                            <span class="input-group-addon"><img src="/dist/img/india.png" class="user-image input-group-addon-img" alt="User Image" /></span>
                            <input type="text" {...register('type_pincode')} defaultValue={member.pincode} className="form-control" name="type_pincode" placeholder="Pincode" onChange={handlePincode()} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div class="input-group">
                            <span class="input-group-addon">State</span>
                            <select className="form-control" {...register('state')}>
                                <option value="" disabled selected>State</option>
                                {states &&
                                    states.map((state, index) => (
                                        <option key={index} value={state}>
                                            {state}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div class="input-group">
                            <span class="input-group-addon">City</span>
                            <select className="form-control" {...register('pincode')}>
                                <option value="" disabled selected>City</option>
                                {pincodes &&
                                    pincodes.map((pincode, index) => (
                                        <option key={index} value={pincode.id}>
                                            {pincode.village}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Anything You Would Like To Say About You</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" {...register('anything_you_would_like_to_say_about_you')} defaultValue={member.anything_you_would_like_to_say_about_you} rows="3" name="anything_you_would_like_to_say_about_you" placeholder="Anything You Would Like To Say About You"></textarea>
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                    {/* <input type="submit" /> */}
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            {/* {!isAuth() ? <Redirect to="/signup" /> : null} */}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <div class="pull-right box-tools">
                        <Link to={`/members`} className="btn btn-primary btn-sm btn-flat"><i className="fa fa-arrow-left" title="Back"></i> Back to Members</Link>
                    </div>
                </div>
                <section className="content">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="box">
                                <div className="box-body box-profile">
                                    <img className="profile-user-img img-responsive img-circle" src="../../dist/img/user.png" alt="User profile picture" />

                                    <h3 className="profile-username text-center">{member.user_name}</h3>

                                    <p className="text-muted text-center">
                                        Joined @ {TimeAgo(member.user_createdAt)}
                                        <br /><b className={member.bg_class}>{member.user_status_name}</b>
                                    </p>

                                    <ul className="list-group list-group-unbordered">
                                        <li className="list-group-item">
                                            <b>Chapter</b> <a className="pull-right">{member.chapter_display_title}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Zone</b> <a className="pull-right">{member.zone_display_title}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <form method="post" className="form-horizontal">
                                                <select className="form-control" value={member.user_status_id} onChange={handleChangeUserStatus('user_status_id')}>
                                                    <option value="" disabled selected>Select your option</option>
                                                    {userStatus &&
                                                        userStatus.map((us, index) => (
                                                            <option key={index} value={us.id}>
                                                                {us.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </form>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#details" data-toggle="tab">Details</a></li>
                                    <li><a href="#testimonials" data-toggle="tab">Testimonials</a></li>
                                    {/* <li><a href="#timeline" data-toggle="tab">Network</a></li> */}
                                </ul>
                                <div className="tab-content">
                                    <div className="active tab-pane" id="details">
                                        {lForm()}
                                        {/* <p>will display full details of this member</p> */}
                                    </div>
                                    <div className="tab-pane" id="testimonials">
                                        <ListTestimonial id={urlParams.id} />
                                    </div>

                                    {/* <div className="tab-pane" id="timeline">
                                        <p>will show internal network of this user</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
            </div>
        </Layout>
    )

    // return (
    //     <div>
    //         <h1>sdfsd</h1>
    //         {lForm()}
    //     </div>
    // )
}

export default DetailsMember;