import React from "react";

function InputError({ errors, field }) {

    if (!errors[field]) {
        return <></>
    }

    return (
        <p style={{
            color: '#dd4b39',
            fontSize: 'small'
        }}>required field</p>
    )
}

// style={{ 'border': errors.name ? '2px solid #DD4B39' : '' }} 

export default InputError;