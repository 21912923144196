import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";

import { useForm } from 'react-hook-form';

import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
import ScreenLoader from "../../layout/ScreenLoader";

const DetailsZone = ({ history }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [zone, setZone] = useState([]);
    const [members, setMembers] = useState([]);
    const urlParams = useParams();

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.members}?per_page=10000`
        })
            .then(response => {
                setMembers(response.data.data);
                Object.keys(response.data).forEach(key => {
                    // console.log(key, obj[key]);
                    setValue(key, response.data[key]);
                });
                // console.log(response);
            })
            .catch(e => {
                console.log(e);
            });

        Axios({
            method: 'GET',
            url: `${configURL.zones}/${urlParams.id}`
        })
            .then(response => {
                setZone(response.data);
                Object.keys(response.data).forEach(key => {
                    // console.log(key, obj[key]);
                    setValue(key, response.data[key]);
                });
                // console.log(response);
            })
            .catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false);
            });
    };

    const handleUpdate = (data) => {
        console.log(data);
        let lFormData = {};
        lFormData['name'] = data.name;
        lFormData['display_title'] = data.display_title;
        lFormData['chapter_head_user_id'] = data.chapter_head_user_id;
        lFormData['active'] = data.active;

        setLoader(true);

        Axios({
            method: 'PUT',
            url: `${configURL.zones}/${urlParams.id}`,
            data: lFormData
        })
            .then(response => {
                console.log("UPDATE Zone RESPONSE", response);
                toast.success(response.data.message);
                history.push("/zones");
            })
            .catch(error => {
                console.log('UPDATE Zone ERROR', error.response.data.error);
                toast.error(error.response.data.error);
            }).finally(() => {
                setLoader(false);
            });
    };

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleUpdate)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="name" type="text" {...register('name', { required: 'required!' })} defaultValue={zone.name} className="form-control" placeholder="Name" />
                        <InputError errors={errors} field={'name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Display Title <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('display_title', { required: 'required!' })} defaultValue={zone.display_title} className="form-control" placeholder="Display Title" name="display_title" />
                        <InputError errors={errors} field={'display_title'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Chapter Head</label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('chapter_head_user_id')} defaultValue={zone.chapter_head_user_id}>
                            <option value="" selected>Select your option</option>
                            {members &&
                                members.map((member, index) => (
                                    <option key={index} value={member.user_id}>
                                        {member.user_name}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Active</label>
                    <div className="col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" {...register('active')} />
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <ToastContainer />
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-area-chart"></i></span> Detail Zone</h3>
                    <div class="pull-right box-tools">
                        <Link to={`/zones`} className="btn btn-primary btn-sm btn-flat"><i className="fa fa-arrow-left" title="Back"></i> Back to Zones</Link>
                    </div>
                </div>
                {lForm()}
            </div>
        </Layout>
    )
}

export default DetailsZone;