import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import LayoutExternal from "../../layout/LayoutExternal";


const VisitorExternalThankYou = () => {
    window.setTimeout(function(){
        // Move to a new location or you can do something else
        window.location.href = "https://bine.beyondnetworking.in";
    }, 5000);

    return (
        <LayoutExternal>
            <div class="alert alert-success alert-dismissable">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h4>	<i class="icon fa fa-check"></i> Registration Success!</h4>
                Thank you for registering as Visitor for BINE. Please wait while we redirect you to the BINE website.
            </div>
        </LayoutExternal>
    )
}

export default VisitorExternalThankYou;