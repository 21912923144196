import React from "react";
import { Link } from "react-router-dom";
import './Sidebar.css';

const SideBar = () => {

    const pathname = window.location.pathname;

    const auth = JSON.parse(localStorage.getItem('user'))
    const menus = auth.menus.map((menu, index) => {
        return (<li key={index} className={menu.path === pathname ? 'active' : ''}>
            <Link to={menu.path}><i className={menu.icon}></i> <span>{menu.display_name}</span></Link>
        </li>)
    });;

    return (
        <div className="main-sidebar">
            <section className="sidebar">
                <ul className="sidebar-menu" data-widget="tree">
                    {menus}
                </ul>
            </section>
        </div>
    )
}

export default SideBar;