import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";

import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import useSearch from "../../utility/useSearch";
import Status from "../../utility/Status";

const Zones = ({ history }) => {

    const [zones, setZones] = useState([]);

    const [loader, setLoader] = useState(true);
    const [search, Searchable] = useSearch();

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    useEffect(() => {
        retrieveData({ selectedPageNumber: 1, search: '' });
    }, []);

    const retrieveData = ({ selectedPageNumber = 1, search = '' }) => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.zones}?current_page=${selectedPageNumber}&search=${search}`
        })
            .then(response => {
                setZones(response.data.data);
                setPagination(response.data.pagination);
            })
            .catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false);
            });
    };

    const handleDelete = (id) => (event) => {
        // alert(id);
        console.log(id);
        let text = "Are You sure want to delete? Data once deleteted cannot be recover!";
        if (window.confirm(text) == true) {
            setLoader(true);
            Axios({
                method: 'DELETE',
                url: `${configURL.zones}/${id}`
            })
                .then(response => {
                    toast.success(response.data.message);
                    retrieveData();
                })
                .catch(e => {
                    console.log(e);
                }).finally(() => {
                    setLoader(false)
                });
        }
    };

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-primary">
                <ToastContainer />
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-area-chart"></i></span> Zones</h3>
                    <div className="box-tools pull-right" data-toggle="tooltip" title="" data-original-title="Status">
                        <div className="btn-group" data-toggle="btn-toggle">
                            <Link to="/create-zone" className="btn btn-danger btn-sm btn-flat"><i>Add Zone</i></Link>
                        </div>
                    </div>
                </div>
                {Searchable((search) => retrieveData({ search }))}
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Name</th>
                                <th>Display Name</th>
                                <th>Chapter Head</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                            {zones &&
                                zones.map((zone, index) => (
                                    <tr key={index}>
                                        <td>{AutoSerialNumber(index, pagination)}</td>
                                        <td>{zone.name}</td>
                                        <td>{zone.display_title}</td>
                                        <td>{zone.chapter_head_user_name}</td>
                                        <td><Status.Render status={zone.active}/></td>
                                        <td>
                                            <Link to={`/detail-zone/${zone.id}`} className="btn btn-primary btn-xs btn-flat"><i className="fa fa-list"></i></Link>
                                            &nbsp;| <button title="delete" className="btn btn-danger btn-xs btn-flat" onClick={handleDelete(zone.id)}><i className="fa fa-trash"></i></button>
                                            &nbsp;| <Link to={`/chapters/${zone.id}`} className="btn btn-info btn-xs btn-flat">Chapters</Link>
                                        </td>
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                </div>

                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            retrieveData({ selectedPageNumber, search });
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}


export default Zones;