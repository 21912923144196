import axios from "axios";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { isAuth, signout } from './Helper';
import createBrowserHistory from 'history/createBrowserHistory';
const history = createBrowserHistory();

let notificationTimer = null;
const Axios = axios.create();
/**
 * Here Modify request data if needed like Login token, company ids
 */
Axios.interceptors.request.use(request => {
    const lToken = Cookies.get('token');
    // console.log(lToken);
    request.headers["Authorization"] = `Bearer ${lToken}`;
    return request;
});

/**
 * Intercept Responses and check if response status os OK.
 */
Axios.interceptors.response.use(response => {
    return response;
}, error => {
    toast.error(error.response.data.error);
    console.log('axios Error');
    console.log("Axios Interceptor : ", error.response.status);
    // if(error.response.status == 401)
    // {
    //     signout(() => {
    //         history.push('/signin');
    //     });
    // }
});

export default Axios;
