import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";

import { useForm } from 'react-hook-form';

import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
import ScreenLoader from "../../layout/ScreenLoader";

const CreateRole = ({ history }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const urlParams = useParams();
    const [loader, setLoader] = useState(false);

    const handleCreate = (data) => {
        setLoader(true);
        console.log(data);
        let lFormData = {};
        lFormData['code'] = data.code;
        lFormData['display_title'] = data.display_title;
        lFormData['description'] = data.description;
        lFormData['active'] = data.active;
        Axios({
            method: 'POST',
            url: `${configURL.createRole}`,
            data: lFormData
        })
            .then(response => {
                console.log("CREATE ROLE RESPONSE", response);
                toast.success(response.data.message);
                history.push("/roles");
            })
            .catch(error => {
                console.log('CREATE ROLE ERROR', error.response.data.error);
                toast.error(error.response.data.error);
            }).finally(() => {
                setLoader(false);
            })
    };

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleCreate)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Code <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="code" type="text" {...register('code', { required: 'required!' })} className="form-control" placeholder="Code" />
                        <InputError errors={errors} field={'code'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Display Title <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('display_title', { required: 'required!' })} className="form-control" placeholder="Display Title" name="display_title" />
                        <InputError errors={errors} field={'display_title'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Description <RequiredStar /></label>
                    <div className="col-sm-10">
                        <textarea className="form-control" {...register('description', { required: 'required!' })} rows="3" name="description" placeholder="Description"></textarea>
                        <InputError errors={errors} field={'description'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Active</label>
                    <div className="col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" {...register('active')} />
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <ToastContainer />
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-user-secret"></i></span> Add Role</h3>
                    <div class="pull-right box-tools">
                        <Link to={`/roles`} className="btn btn-primary btn-sm btn-flat"><i className="fa fa-arrow-left" title="Back"></i> Back to Roles</Link>
                    </div>
                </div>
                {lForm()}
            </div>
        </Layout>
    )
}

export default CreateRole