import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";

import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import useSearch from "../../utility/useSearch";
import { useForm } from "react-hook-form";
import RequiredStar from "../../utility/RequiredStar";
import InputError from "../../utility/InputError";

const BoardMemebers = () => {

    const currentUserHigherRole = Math.min(...JSON.parse(JSON.parse(localStorage.getItem('user')).roles))

    const [members, setMembers] = useState([]);

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    const [loader, setLoader] = useState(true);
    const [search, Searchable] = useSearch();

    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

    useEffect(() => {
        retrieveMembers({ selectedPageNumber: 1, search: '' });
    }, []);

    const retrieveMembers = ({ selectedPageNumber = 1, search = '' }) => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.members}?current_page=${selectedPageNumber}&search=${search}`
        }).then(response => {
            setMembers(response.data.data.map((ele) => {
                const roles = JSON.parse(ele.user_role_ids);

                ele.is_role_bm = roles.indexOf(2) > 0;
                ele.is_role_ch = roles.indexOf(3) > 0;
                ele.is_role_eb = roles.indexOf(4) > 0;
                return ele;
            }));
            // console.log(response.data.data);
            setPagination(response.data.pagination);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    const addRole = (userID, roleID) => () => {
        setLoader(true);
        Axios({
            method: 'POST',
            url: `${configURL.addRole}/${userID}`,
            data: {
                role_id: roleID
            }
        }).then(response => {
            retrieveMembers({ selectedPageNumber: 1, search: '' })
        }).catch(e => {
            console.log(e);
        });
    }

    const removeRole = (userID, roleID) => () => {
        setLoader(true);
        Axios({
            method: 'POST',
            url: `${configURL.removeRole}/${userID}`,
            data: {
                role_id: roleID
            }
        }).then(response => {
            retrieveMembers({ selectedPageNumber: 1, search: '' })
        }).catch(e => {
            console.log(e);
        });
    }

    const assignUser = (email, name) => {
        reset({ email: email, name: name, newpassword: '', confirm_newpassword: '' })
    }

    const handleChangePassword = (formData) => {
        if (formData.newpassword.length < 8) {
            toast.error('New password should contain minimum 8 characters!');
            return;
        }

        if (formData.newpassword !== formData.confirm_newpassword) {
            toast.error('Please enter confirm password same as new password!');
            return;
        }

        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.changePasswordByAdmin}`,
            data: {
                email: formData.email,
                newpassword: formData.newpassword
            }
        }).then(response => {
            toast.success(response.data.message);
            document.getElementById('closeChangePasswordByPopup').click();
        }).catch(error => {
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        });
    }

    const changePasswordPopupForm = () => (
        <form onSubmit={handleSubmit(handleChangePassword)}>
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-5 text-right control-label">Name</label>
                    <div className="col-sm-7">
                        <input type="text" disabled {...register('name')} className="form-control" placeholder="Please select user from list" name="name" />
                    </div>
                </div><br />
                <div className="form-group">
                    <label className="col-sm-5 text-right control-label">Email</label>
                    <div className="col-sm-7">
                        <input type="email" disabled {...register('email')} className="form-control" placeholder="Please select user from list" name="email" />
                    </div>
                </div><br />
                <div className="form-group">
                    <label className="col-sm-5 text-right control-label">New Password <br /><span style={{ fontSize: '0.8em', color: 'red' }}>(Minimum 8 characters)</span> <RequiredStar /></label>
                    <div className="col-sm-7">
                        <input type="password" {...register('newpassword', { required: 'required!' })} className="form-control" placeholder="New Password" name="newpassword" />
                        <InputError errors={errors} field={'newpassword'} />
                    </div>
                </div><br />
                <div className="form-group">
                    <div className="row">
                    </div>
                    <label className="col-sm-5 text-right control-label">Confirm New Password <RequiredStar /></label>
                    <div className="col-sm-7">
                        <input type="password" {...register('confirm_newpassword', { required: 'required!' })} className="form-control" placeholder="Confirm New Password" name="confirm_newpassword" />
                        <InputError errors={errors} field={'confirm_newpassword'} />
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="col-sm-12">
                    <div className="pull-right">
                        <button type="submit" className="btn btn-danger btn-block btn-flat">Change Password</button>
                    </div>
                </div>
            </div>
        </form>
    )

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-primary">
                <div className="box-header">
                    <h3 className="box-title">
                        <span className="btn btn-primary btn-xs btn-flat"><i className="fa fa-users"></i></span> Board Members
                    </h3>
                </div>
                {Searchable((search) => retrieveMembers({ search }))}
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr. No.</th>
                                <th>User</th>
                                <th>Chapter</th>
                                <th>Zone</th>
                                <th>Business</th>
                                <th>Roles</th>
                                {([1, 2].indexOf(currentUserHigherRole) > -1) &&
                                    <th>Actions</th>}
                            </tr>
                            {members &&
                                members.map((member, index) => (
                                    <tr key={index}>
                                        <td>{AutoSerialNumber(index, pagination)}</td>
                                        <td>{member.user_name}</td>
                                        <td>{member.chapter}</td>
                                        <td>{member.zone}</td>
                                        <td>{member.business_name}</td>
                                        <td>
                                            <div class="btn-group">
                                                <button title="BM" type="button" class={member.is_role_bm ? "btn btn-success" : "btn btn-disabled"} disabled={([1].indexOf(currentUserHigherRole) > -1) === false} onClick={!member.is_role_bm ? addRole(member.user_id, 2) : removeRole(member.user_id, 2)}><i class="fa fa-user-secret"></i></button>

                                                <button title="CH" type="button" class={member.is_role_ch ? "btn btn-success" : "btn btn-disabled"} disabled={([1, 2].indexOf(currentUserHigherRole) > -1) === false} onClick={!member.is_role_ch ? addRole(member.user_id, 3) : removeRole(member.user_id, 3)}><i class="fa fa-user-md"></i></button>

                                                <button title="EB" type="button" class={member.is_role_eb ? "btn btn-success" : "btn btn-disabled"} disabled={([1, 2, 3].indexOf(currentUserHigherRole) > -1) === false} onClick={!member.is_role_eb ? addRole(member.user_id, 4) : removeRole(member.user_id, 4)}><i class="fa fa-user"></i></button>
                                            </div>
                                        </td>
                                        {([1, 2].indexOf(currentUserHigherRole) > -1) &&
                                            <td>
                                                <span
                                                    data-toggle="modal" data-target="#change-password-by-popup"
                                                    className="btn btn-danger btn-xs"
                                                    onClick={() => assignUser(member.user_email, member.user_name)}
                                                ><i className="fa fa-user-secret"></i> Change Password</span>
                                            </td>}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <ToastContainer />
                <div class="modal fade text-center" id="change-password-by-popup" style={{ display: 'none' }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" id="closeChangePasswordByPopup" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span></button>
                                <h4 class="modal-title">Change password</h4>
                            </div>
                            <div class="modal-body">
                                {changePasswordPopupForm()}
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div>

                    </div>

                </div>

                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            retrieveMembers({ selectedPageNumber, search });
                        }}
                    />
                </div>
            </div></Layout>
    )
}

export default BoardMemebers;