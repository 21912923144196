import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { Link, useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";
import ScreenLoader from "../../layout/ScreenLoader";
import Axios from "../../utility/Axios";
import TimeAgo from "../../utility/TimeAgo"

const Chat = ({ UserID }) => {

    const loggedUser = JSON.parse(localStorage.getItem('user'));

    const fromOrToID = (message) => {
        if (message.from_user_id === loggedUser.id) {
            return message.to_user_id;
        }

        if (message.to_user_id === loggedUser.id) {
            return message.from_user_id;
        }

        return 0;
    }

    const testimonialTemplate = (message) => {
        let firstPerson = '';
        let secondPerson = '';

        let sentOrRecieved = '';

        let template = '';

        if (message.from_user_id === loggedUser.id) {
            firstPerson = 'You';
            secondPerson = message.to_user_name;
            sentOrRecieved = 'sent';

            template = `You have requested ${secondPerson} for writing a testimonial!`;
        }

        if (message.to_user_id === loggedUser.id) {
            firstPerson = message.from_user_name;
            secondPerson = 'You';
            sentOrRecieved = 'received';

            template = `${firstPerson} have requested you for writing a testimonial!`;
        }

        return (
            <div className="direct-chat-text" style={{ 'backgroundColor': '#FFF', 'color': '#000' }}>
                <span className="text-purple small text-bold">{template}</span>
                <br /><br />
                {message.message}
                {sentOrRecieved === 'received' &&
                    <>
                        <br /><br />
                        <Link target={"_blank"} to={`/testimonials/${message.from_user_id}?refmsgid=${message.id}`} className="btn btn-primary btn-xs">
                            <i className="fa fa-commenting"></i> Click here to write a testimonial
                        </Link>
                    </>
                }
            </div>
        );

    }

    const { register, handleSubmit, setValue, reset, setFocus } = useForm();

    const [messages, setMessages] = useState([]);

    const [loader, setLoader] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const [tick, setTick] = useState(0);

    // @audit used some hard coded database values

    const [writingMode, setWritingMode] = useState(1);

    const [member, setMember] = useState({});

    const [UIConfig, setUIConfig] = useState({
        'send.button.visible': 'Send',
        'send.button.text': 'Send',
        'send.button.class': 'bg-success',

        'cancel.button.visible': false,
        'cancel.button.text': 'Cancel',
        'cancel.button.class': 'bg-red',

        'message.box.class': '',

        'message.box.placeholder': 'Type message and hit enter...'
    });

    useEffect(() => {
        if (writingMode === 1) {
            setUIConfig({
                ...{
                    'send.button.visible': 'Send',
                    'send.button.text': 'Send',
                    'send.button.class': 'bg-green',

                    'cancel.button.visible': false,
                    'cancel.button.text': 'Cancel',
                    'cancel.button.class': 'bg-red',

                    'message.box.class': '',
                    'message.box.placeholder': 'Type message and hit enter...'
                }
            })
        }

        if (writingMode === 2) {
            setUIConfig({
                ...{
                    'send.button.visible': true,
                    'send.button.text': 'Send Request',
                    'send.button.class': 'bg-purple',

                    'cancel.button.visible': true,
                    'cancel.button.text': 'Cancel',
                    'cancel.button.class': 'bg-red',

                    'message.box.class': 'text-purple',
                    'message.box.placeholder': 'Write something to ask for testimonial...'
                }
            })
        }
    }, [writingMode]);

    // Fetch message on every n miliseconds
    // @audit-issue sometimes show previous person messages
    useEffect(function () {
        const timer = setTimeout(() => {
            setTick(tick + 1)
            retrieveData();
        }, 3000);

        return () => clearTimeout(timer);
    }, [tick]);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        setRefresh(true);
        setMember({});
        setMessages([]);
        setLoader(true);

        Axios({
            method: 'GET',
            url: `${configURL.detailMember}/${UserID}`
        }).then(response => {
            setMember(response.data.data);
        }).finally(() => {
            // setRefresh(false);
        });

        Axios({
            method: 'POST',
            data: {
                person_user_id: UserID || null
            },
            url: `${configURL.myMessagesWithPerson}`
        }).then((response) => {
            setMessages(response.data.data.reverse());
            // setTimeout(() => {
            //     scrollToBottom();
            // }, 1000);
        }).catch((error) => {
            setMessages([]);
        }).finally(() => {
            setFocus('message');
            setRefresh(false);
            setLoader(false);
        })
    }, [UserID]);

    const retrieveData = () => {
        Axios({
            method: 'POST',
            data: {
                person_user_id: UserID || null
            },
            url: `${configURL.myMessagesWithPerson}?_id=${UserID}`
        }).then((response) => {
            setMessages(response.data.data.reverse());
        }).catch((error) => {
            setMessages([]);
        }).finally(() => {
            setFocus('message');
        })
    }

    const handleCompose = (data) => {
        if (data.message.length < 1) {
            toast.error('Please write something...');
            return;
        }

        setLoader(true);

        Axios({
            method: 'POST',
            data: {
                to_user_id: UserID || null,
                message_type_id: writingMode,
                message: data.message
            },
            url: `${configURL.sendMessage}`
        }).then((response) => {
            retrieveData();
            reset({ message: '' });

            if (writingMode === 2) {
                toast.success('Testimonial request sent!');
            }

        }).finally(() => {
            setLoader(false);
            setWritingMode(1);
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="box box-success direct-chat direct-chat-success">
                <div className="box-header with-border bg-green">
                    <h3 className="box-title">{member.user_name}</h3>
                    <div className="box-tools pull-right">
                        <button className={writingMode !== 2 ? "btn bg-purple" : "btn bg-red"} onClick={() => { setWritingMode(writingMode === 2 ? 1 : 2); setFocus('message') }}>
                            <i className="fa fa-commenting"></i> {writingMode === 2 ? 'Cancel' : 'Send'} Testimonial Request
                        </button>
                    </div>
                </div>
                <div className="box-body bg-gray-light">
                    <div className="direct-chat-messages" style={{ "height": "50vh" }}>
                        {refresh && <ThreeDots
                            height="30"
                            width="80"
                            radius="2"
                            color="#00a65a"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />}
                        {messages.map((message, index) => (
                            <div className={message.from_user_id == loggedUser.id ? 'direct-chat-msg right' : 'direct-chat-msg'}>
                                <div className="direct-chat-info clearfix">
                                    <span className={message.from_user_id == loggedUser.id ? 'direct-chat-name pull-right' : 'direct-chat-name pull-left'}>{message.from_user_name}</span>
                                    <span className={message.from_user_id == loggedUser.id ? 'direct-chat-timestamp pull-left' : 'direct-chat-timestamp pull-right'}>{TimeAgo(message.createdAt)}</span>
                                </div>
                                <img className="direct-chat-img" src="../dist/img/user.png" alt="user" />
                                {message.message_type_id === 2
                                    ? testimonialTemplate(message)
                                    : <div className="direct-chat-text">
                                        {message.message}
                                    </div>}
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                </div>
                <div className="box-footer">
                    <form onSubmit={handleSubmit(handleCompose)}>
                        <div className="input-group">
                            <input type="text" disabled={loader} name="message" placeholder={UIConfig['message.box.placeholder']} className={"form-control " + UIConfig['message.box.class']} {...register('message')} />
                            <span className="input-group-btn">
                                {UIConfig['send.button.visible'] && <button type="submit" disabled={loader} className={"btn " + UIConfig['send.button.class']}>{UIConfig['send.button.text']}</button>}
                                {UIConfig['cancel.button.visible'] && <button type="button" onClick={() => setWritingMode(1)} disabled={loader} className={"btn " + UIConfig['cancel.button.class']}>{UIConfig['cancel.button.text']}</button>}
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Chat;