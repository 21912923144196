import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from './Header';
import SideBar from './Sidebar';
import Foolter from './Footer';
import { isAuth } from "../utility/Helper";
const Layout = ({ children }) => {
    return (<div className="wrapper">
        {!isAuth() ? <Redirect to="/signin" /> : null}
        <Header></Header>
        <SideBar></SideBar>
        <div className="content-wrapper">
            <section className="content container-fluid">{children}</section>
        </div>
        <Foolter></Foolter>
    </div>)
}

export default Layout;
