import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";

import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import useSearch from "../../utility/useSearch";
import Status from "../../utility/Status";
import TimeAgo from "../../utility/TimeAgo"
import moment from "moment/moment";

const Meetings = ({ history }) => {

    const [rows, setRows] = useState([]);

    const [loader, setLoader] = useState(true);
    const [showCreateButton, setShowCreateButton] = useState(false);
    const [search, Searchable] = useSearch();

    const [meetingCategories, setMeetingCategories] = useState([]);

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    useEffect(() => {
        retrieveData({ selectedPageNumber: 1, search: '' });
    }, []);

    const retrieveData = ({ selectedPageNumber = 1, search = '' }) => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.Meeting.Index}?current_page=${selectedPageNumber}&search=${search}`
        }).then(response => {
            setRows(response.data.data);
            setPagination(response.data.pagination);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });

        Axios({
            method: 'GET',
            url: configURL.Meeting.Category.Index
        }).then(response => {
            setMeetingCategories(response.data);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            let user = JSON.parse(localStorage.getItem('user'))
            let roles = JSON.parse(user.roles)
            const allowedRoles = [1, 2, 3, 4];
            roles = roles.filter((ele) => {
                return allowedRoles.includes(ele);
            });

            setShowCreateButton(roles.length > 0);
        });
    };

    const handleDelete = (id) => (event) => {
        let text = "Are You sure want to delete? Data once deleteted cannot be recover!";

        if (window.confirm(text) == true) {
            setLoader(true);
            Axios({
                method: 'DELETE',
                url: `${configURL.Meeting.Delete}${id}`
            }).then(response => {
                toast.success(response.data.message);
                retrieveData();
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false)
            });
        }
    };

    const parseDate = (dateString) => {
        if (dateString === null || typeof dateString !== 'string' || dateString.length === 0) {
            return '';
        }

        let d = dateString.replace('.000Z', '').split('T').join(' ');
        return moment(d, 'YYYY-MM-DD HH:mm:ss').format('LLLL');
    }

    const createMeetingUI = () => {

        if (meetingCategories.length < 1) {
            return (
                <div></div>
            )
        }

        const closeAndRedirect = (meeting_category_id) => (event) => {
            document.getElementById('closeMeetingCategoryPopup').click()
            history.push('/meetings/create/' + meeting_category_id);
            return;
        }

        return (
            <div>
                {meetingCategories.map((e, index) => (
                    <div key={index} className="btn-group" data-toggle="btn-toggle">
                        <button onClick={closeAndRedirect(e.id)} className="btn btn-danger btn-md">
                            {e.display_title}
                        </button>
                        &nbsp;
                    </div>
                ))}
            </div>
        )
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-primary">
                <ToastContainer />
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat">
                        <i className="fa fa-area-chart"></i></span> Meetings
                    </h3>
                    <div className="box-tools pull-right" data-toggle="tooltip" title="" data-original-title="Status">
                        {showCreateButton && <div className="btn-group" data-toggle="btn-toggle">
                            <button className="btn btn-danger btn-sm btn-flat" data-toggle="modal" data-target="#selectMeetingCategoryPopup">
                                <i>Create New Meeting</i>
                            </button>
                        </div>}
                    </div>

                </div>
                {Searchable((search) => retrieveData({ search }))}
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Name</th>
                                <th>Display Name</th>
                                <th>Event Date</th>
                                <th>Active</th>
                                <th>Invite Members/Visitors</th>
                                <th>Actions</th>
                            </tr>
                            {rows &&
                                rows.map((row, index) => (
                                    <tr key={index}>
                                        <td>{AutoSerialNumber(index, pagination)}</td>
                                        <td>{row.name}</td>
                                        <td>{row.display_title}</td>
                                        <td>{parseDate(row.event_date)}</td>
                                        <td><Status.Render status={row.active} /></td>
                                        <td>
                                            <Link
                                                to={`/meetings/members/add/${row.id}`}
                                                className="btn btn-default btn-xs btn-flat"
                                            ><i className="fa fa-user"></i>&nbsp; Members</Link>
                                            &nbsp;| <Link
                                                to={`/meetings/visitors/add/${row.id}`}
                                                className="btn btn-default btn-xs btn-flat"
                                            ><i className="fa fa-user-secret"></i>&nbsp; Visitors</Link>
                                        </td>
                                        <td>
                                            <Link
                                                to={`/meetings/view/${row.id}`}
                                                className="btn btn-primary btn-xs btn-flat"
                                            ><i className="fa fa-list"></i>&nbsp; Details</Link>
                                            &nbsp;| <button
                                                title="delete"
                                                className="btn btn-danger btn-xs btn-flat"
                                                onClick={handleDelete(row.id)}
                                            ><i className="fa fa-trash"></i>&nbsp; Delete</button>
                                        </td>
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                </div>

                <div class="modal fade text-center" id="selectMeetingCategoryPopup" style={{ display: 'none' }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" id="closeMeetingCategoryPopup" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span></button>
                                <h4 class="modal-title">Please select meeting category</h4>
                            </div>
                            <div class="modal-body">
                                {createMeetingUI()}
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            retrieveData({ selectedPageNumber, search });
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}


export default Meetings;