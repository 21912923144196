import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";
import ScreenLoader from "../../layout/ScreenLoader";
import TimeAgo from "../../utility/TimeAgo"
import { useForm } from 'react-hook-form';

const ListTestimonial = (props) => {

    const { register, handleSubmit, setValue, reset } = useForm();

    const query = new URLSearchParams(window.location.search);
    const refMessageID = query.get('refmsgid');
    const [canWrite, setCanWrite] = useState(true); // changed to `true` as per request

    const [testimonials, setTestimonials] = useState([]);

    const [loader, setLoader] = useState(false);

    useEffect(function () {
        retrieveData();
    }, []);

    const retrieveData = () => {
        setLoader(true);

        Axios({
            method: 'POST',
            data: {
                to_user_id: props.id || null
            },
            url: `${configURL.testimonials}`
        }).then((response) => {
            setTestimonials(response.data.data.reverse());
        }).catch((error) => {
            setTestimonials([]);
        }).finally(() => {
            setLoader(false);
        })

        // if (props.id) {
        //     Axios({
        //         method: 'GET',
        //         url: `${configURL.detailMember}/${props.id}`
        //     }).then(response => {
        //         setCanWrite(response.data.data.in_network === 1)
        //     }).catch(e => {
        //         console.log(e);
        //     }).finally(() => {
        //         setLoader(false);
        //     });
        // }

    }

    const handleWrite = (data) => {
        if (!data.testimonial && data.testimonial.trim().length < 1) {
            toast.error('Please write something!');
            return;
        }

        setLoader(true);

        Axios({
            method: 'POST',
            data: {
                to_user_id: props.id,
                testimonial: data.testimonial.trim(),
                reference_message_id: refMessageID || null
            },
            url: `${configURL.writeTestimonial}`
        }).then((response) => {
            toast.success('Testimonial added! Waiting for approval.');
            reset({
                testimonial: ''
            });
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoader(false);
            retrieveData();
        })
    };

    const handleApprove = (id) => (event) => {
        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.approveTestimonial}/${id}`
        }).then((response) => {
            toast.success('Testimonial approved!');
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoader(false);
            retrieveData();
        })
    };

    const handleDelete = (id) => (event) => {
        let text = "Are You sure want to delete? Data once deleteted cannot be recover!";

        if (!window.confirm(text)) {
            return;
        }

        setLoader(true);

        Axios({
            method: 'DELETE',
            url: `${configURL.deleteTestimonial}/${id}`
        }).then((response) => {
            toast.success('Testimonial deleted!');
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoader(false);
            retrieveData();
        })
    };

    return (
        <>
            {loader && <ScreenLoader />}
            <ToastContainer />

            {props.id
                && canWrite
                && props.id > 0
                && JSON.parse(localStorage.getItem('user')).id != props.id
                && <div className="box box-warning">
                    <form onSubmit={handleSubmit(handleWrite)} className="form-horizontal">
                        <div className="box-body">
                            <div className="form-group">
                                <div className="col-sm-12">
                                    <textarea className="form-control" {...register('testimonial')} rows="3" name="testimonial" placeholder="Anything You Would Like To Say..."></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="box-footer">
                            <div className="pull-right">
                                <button type="submit" className="btn btn-warning btn-block btn-flat">Post</button>
                            </div>
                        </div>
                    </form>
                </div>}

            <ul className="timeline">
                {testimonials.map((testimonial, index) => (
                    <li key={testimonial.id}>
                        <i className={testimonial.approved_date ? 'fa fa-commenting bg-green' : 'fa fa-commenting bg-yellow'}></i>
                        <div className="timeline-item">
                            <span className="time">
                                <i className="fa fa-calender"></i> {TimeAgo(testimonial.createdAt)}
                            </span>
                            <h3 className="timeline-header">
                                {testimonial.from_user_name} <small>{!testimonial.approved_date && ' [Pending for acceptance]' }</small>
                                </h3>
                            <div className="timeline-body">
                                {testimonial.testimonial.split('\n').map((str, index2) => <p key={index2}>{str}</p>)}
                            </div>

                            {/** @todo Need data regarding to give permission to approve and delete */}
                            {/** @todo handle testimonial.approved_date */}

                            {!props.id && <div className="timeline-footer">
                                {!testimonial.approved_date &&
                                    <><a className="btn btn-success btn-xs" onClick={handleApprove(testimonial.id)}>Accept</a> | </>}
                                <a className="btn btn-danger btn-xs" onClick={handleDelete(testimonial.id)}>Reject</a>
                            </div>}
                        </div>
                    </li>
                ))}

            </ul>
            {testimonials.length < 1 && <div className="text-center">
                No testimonial found!
            </div>}
        </>
    )
}

export default ListTestimonial;