import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";
import ScreenLoader from "../../layout/ScreenLoader";
import TimeAgo from "../../utility/TimeAgo"
import { useForm } from "react-hook-form";
import RequiredStar from "../../utility/RequiredStar";
import InputError from "../../utility/InputError";
import Pagination from "../../utility/pagination/Pagination";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";

const Network = () => {

    const isLoggedUserID = (id) => {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        return id == currentUser.id;
    }

    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();

    const [myNetworks, setMyNetworks] = useState([]);
    const [incommingNetworks, setIncommingNetworks] = useState([]);
    const [outgoingNetworks, setOutgoingNetworks] = useState([]);

    const [meetingPopupFields, setMeetingPopupFields] = useState({
        meeting_type: '',
        title: '',
        user_id: '',
        enabledAmount: false,
    });

    const [loader, setLoader] = useState(true);

    const [activeTab, setActiveTab] = useState('myNetworks');

    const [myNetworkPagination, setMyNetworkPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    const [incommingNetworkPagination, setIncommingNetworkPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    const [outgoingNetworkPagination, setOutgoingNetworkPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    const retriveMyNetworksData = (selectedPageNumber = 1) => {
        setLoader(true);
        setMyNetworks([]);

        Axios({
            method: 'POST',
            data: {
                network_filter_type: 'MY_NETWORK'
            },
            url: `${configURL.networks}?current_page=${selectedPageNumber}`
        }).then(response => {
            setMyNetworks(response.data.data);
            setMyNetworkPagination(response.data.pagination);
        }).catch(e => {
            toast.error('Error!');
        }).finally(function () {
            setLoader(false);
        });
    }

    const retriveIncommingNetworksData = (selectedPageNumber = 1) => {
        setLoader(true);
        setIncommingNetworks([]);

        Axios({
            method: 'POST',
            data: {
                network_filter_type: 'INVITATION_PENDING'
            },
            url: `${configURL.networks}?current_page=${selectedPageNumber}`
        }).then(response => {
            setIncommingNetworks(response.data.data);
            setIncommingNetworkPagination(response.data.pagination);
        }).catch(e => {
            toast.error('Error!');
        }).finally(function () {
            setLoader(false);
        });
    }

    const retriveOutgoingNetworksData = (selectedPageNumber = 1) => {
        setLoader(true);
        setOutgoingNetworks([]);

        Axios({
            method: 'POST',
            data: {
                network_filter_type: 'REQUEST_PENDING'
            },
            url: `${configURL.networks}?current_page=${selectedPageNumber}`
        }).then(response => {
            setOutgoingNetworks(response.data.data);
            setOutgoingNetworkPagination(response.data.pagination);
        }).catch(e => {
            toast.error('Error!');
        }).finally(function () {
            setLoader(false);
        });
    }

    useEffect(() => {
        if (activeTab == 'myNetworks') {
            retriveMyNetworksData();
            return;
        }

        if (activeTab == 'incomming.request') {
            retriveIncommingNetworksData();
            return;
        }

        if (activeTab == 'outgoing.request') {
            retriveOutgoingNetworksData();
            return;
        }
    }, [activeTab]);

    const handleNetworkDelete = (id) => (event) => {
        let text = "Are You sure want to delete? Data once deleteted cannot be recover!";

        if (window.confirm(text) === true) {
            setLoader(true);

            Axios({
                method: 'POST',
                url: `${configURL.rejectNetworkRequest}${id}`
            }).then(response => {
                toast.success('Deleted!');
            }).catch(e => {
                toast.error('Error!');
            }).finally(function () {
                retriveMyNetworksData();
            });
        }
    };

    const handleIncomingAccept = (id) => (event) => {
        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.acceptNetworkRequest}${id}`
        }).then(response => {
            toast.success('Accepted!');
        }).catch(e => {
            toast.error('Error!');
        }).finally(function () {
            retriveIncommingNetworksData();
        });
    };

    const handleIncomingDelete = (id) => (event) => {
        let text = "Are You sure want to delete? Data once deleteted cannot be recover!";

        if (window.confirm(text) === true) {
            setLoader(true);

            Axios({
                method: 'POST',
                url: `${configURL.rejectNetworkRequest}${id}`
            }).then(response => {
                toast.success('Deleted!');
            }).catch(e => {
                toast.error('Error!');
            }).finally(function () {
                retriveIncommingNetworksData();
            });
        }
    };

    const handleOutgoingDelete = (id) => (event) => {
        let text = "Are You sure want to delete? Data once deleteted cannot be recover!";

        if (window.confirm(text) === true) {
            setLoader(true);

            Axios({
                method: 'POST',
                url: `${configURL.rejectNetworkRequest}${id}`
            }).then(response => {
                toast.success('Deleted!');
            }).catch(e => {
                toast.error('Error!');
            }).finally(function () {
                retriveOutgoingNetworksData();
            });
        }
    };

    const setMeeting = (data) => (event) => {
        setMeetingPopupFields({
            meeting_type: data.meeting_type,
            title: data.title,
            user_id: data.user_id,
            enabledAmount: data.enabledAmount
        })

        reset({
            user_name: data.user_name,
            name: '',
            comment: '',
            amount: 0
        })
    }

    const handleMeeting = (formdata) => {
        const currentUser = JSON.parse(localStorage.getItem('user'))
        const currentUserID = currentUser.id;

        let API, data;

        if (meetingPopupFields.meeting_type === 'ONETOONE') {
            API = configURL.memberMeeting.OneToOne.Create
            data = {
                from_user_id: currentUserID,
                to_user_id: meetingPopupFields.user_id,
                name: formdata.name,
                comment: formdata.comment
            }
        }

        if (meetingPopupFields.meeting_type === 'REFERENCE') {
            API = configURL.memberMeeting.Reference.Create
            data = {
                from_user_id: currentUserID,
                to_user_id: meetingPopupFields.user_id,
                name: formdata.name,
                comment: formdata.comment
            }
        }

        if (meetingPopupFields.meeting_type === 'BUSINESSCLOSE') {
            API = configURL.memberMeeting.BusinessClose.Create
            data = {
                from_user_id: currentUserID,
                to_user_id: meetingPopupFields.user_id,
                name: formdata.name,
                comment: formdata.comment,
                amount: formdata.amount,
            }
        }

        setLoader(true);

        Axios({
            method: 'POST',
            url: API,
            data: data
        }).then(response => {
            toast.success('Done!');
            document.getElementById('closeNetworkMeetingPopup').click();
        }).catch(e => {
            toast.error('Error!');
        }).finally(function () {
            setLoader(false);
        });
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <ToastContainer />
            <div className="row">
                <div className="col-lg-3 col-xs-6" onClick={() => setActiveTab('myNetworks')} style={{ cursor: 'pointer' }}>
                    <div className={activeTab === 'myNetworks' ? 'small-box bg-green' : 'small-box bg-gray'}>
                        <div className="small-box-footer text-bold">My Networks</div>
                    </div>
                </div>

                <div className="col-lg-3 col-xs-6" onClick={() => setActiveTab('incomming.request')} style={{ cursor: 'pointer' }}>
                    <div className={activeTab === 'incomming.request' ? 'small-box bg-orange' : 'small-box bg-gray'}>
                        <div href="#" className="small-box-footer text-bold">Approve Requests</div>
                    </div>
                </div>

                <div className="col-lg-3 col-xs-6" onClick={() => setActiveTab('outgoing.request')} style={{ cursor: 'pointer' }}>
                    <div className={activeTab === 'outgoing.request' ? 'small-box bg-purple' : 'small-box bg-gray'}>
                        <div href="#" className="small-box-footer text-bold">Awaiting Approval</div>
                    </div>
                </div>
            </div>

            {activeTab === 'myNetworks' && <div className="box box-primary">
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-users"></i></span> My Networks</h3>
                    <div className="box-tools pull-right" data-toggle="tooltip" title="" data-original-title="Status">
                        <div className="btn-group" data-toggle="btn-toggle">
                            <Link to={`/add-network/`} className="btn btn-danger btn-sm btn-flat"><i>Search/Add Member</i></Link>
                        </div>
                    </div>
                </div>
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Name</th>
                                <th>Business Name</th>
                                <th>Mobile</th>
                                {/* <th>City</th> */}
                                <th>Top Selling Product/Service</th>
                                <th>Actions</th>
                                <th>Meetings</th>
                            </tr>
                            {myNetworks && myNetworks.map((network, index) => (
                                <tr key={index}>
                                    <td>{AutoSerialNumber(index, myNetworkPagination)}</td>
                                    <td>{network.name}</td>
                                    <td>{network.business_name}</td>
                                    <td>{network.mobile_number}</td>
                                    {/* <td>{network.city}</td> */}
                                    <td>{network.top_selling_product_service}</td>
                                    <td>
                                        <Link title="message" to={`/messages/${network.user_id}`} className="btn btn-primary btn-xs btn-flat">
                                            <i className="fa fa-envelope"></i> Send Message
                                        </Link>
                                        &nbsp;| <Link title="profile" to={`/view-member/${network.user_id}`} className="btn btn-primary btn-xs btn-flat">
                                            <i className="fa fa-address-card"></i> View Profile
                                        </Link>
                                        &nbsp;| <Link title="testimonials" to={`/testimonials/${network.user_id}`} className="btn btn-primary btn-xs btn-flat">
                                            <i className="fa fa-commenting"></i> Write Testimonial
                                        </Link>
                                        {network.network_id && (<>
                                            &nbsp;| <button title="delete" className="btn btn-danger btn-xs btn-flat" onClick={handleNetworkDelete(network.network_id)}>
                                                <i className="fa fa-user-times"></i> Remove
                                            </button>
                                        </>)}
                                    </td>
                                    <td>
                                        <span
                                            data-toggle="modal" data-target="#network-meeting-popup"
                                            className="btn btn-success btn-xs"
                                            onClick={setMeeting({ meeting_type: 'ONETOONE', title: 'One-to-One Meeting', user_name: network.name, user_id: network.user_id, enabledAmount: false })}
                                        >One-to-One</span>
                                        &nbsp;| <span
                                            data-toggle="modal" data-target="#network-meeting-popup"
                                            className="btn btn-success btn-xs"
                                            onClick={setMeeting({ meeting_type: 'REFERENCE', title: 'Reference Meeting', user_name: network.name, user_id: network.user_id, enabledAmount: false })}
                                        >Reference</span>
                                        &nbsp;| <span
                                            data-toggle="modal" data-target="#network-meeting-popup"
                                            className="btn btn-success btn-xs"
                                            onClick={setMeeting({ meeting_type: 'BUSINESSCLOSE', title: 'Business Close Meeting', user_name: network.name, user_id: network.user_id, amount: 0.0, enabledAmount: true })}
                                        > Business Close</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div class="modal fade text-center" id="network-meeting-popup" style={{ display: 'none' }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header bg-green">
                                <button type="button" id="closeNetworkMeetingPopup" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span></button>
                                <h4 class="modal-title" style={{ textTransform: 'uppercase' }}>{meetingPopupFields.title}</h4>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={handleSubmit(handleMeeting)}>
                                    <div className="box-body">
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">User</label>
                                            <div className="col-sm-10">
                                                <input type="text" {...register('user_name')} disabled className="form-control" name="user_name" placeholder="" />
                                            </div>
                                        </div><br /><br />
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Name <RequiredStar /></label>
                                            <div className="col-sm-10">
                                                <input type="text" {...register('name', { required: 'required!' })} className="form-control" name="name" placeholder="" />
                                            </div>
                                        </div><br /><br />
                                        <div className="form-group">
                                            <label className="col-sm-2 control-label">Comment <RequiredStar /></label>
                                            <div className="col-sm-10">
                                                <textarea className="form-control" {...register('comment', { required: 'required!' })} rows="3" name="comment" placeholder=""></textarea>
                                            </div>
                                        </div><br /><br />
                                        {meetingPopupFields.enabledAmount && (<div className="form-group">
                                            <div className="row">
                                            </div><br />
                                            <label className="col-sm-2 control-label">Amount <RequiredStar /></label>
                                            <div className="col-sm-10">
                                                <input type="number" {...register('amount', { required: meetingPopupFields.enabledAmount ? 'required!' : false })} className="form-control" name="amount" placeholder="" />
                                            </div>
                                        </div>)}
                                    </div>
                                    <div className="box-footer">
                                        <div className="col-sm-12">
                                            <div className="pull-right">
                                                <button type="submit" className="btn bg-green btn-block btn-flat">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div>

                    </div>

                </div>
                <div className="box-footer">
                    <Pagination
                        perPage={myNetworkPagination.per_page}
                        total={myNetworkPagination.total}
                        onPageChange={(selectedPageNumber) => {
                            retriveMyNetworksData(selectedPageNumber);
                        }}
                    />
                </div>
            </div>}

            {activeTab === 'incomming.request' && <div className="box box-primary">
                <div className="box-header">
                    <h3 className="box-title"><span className="btn bg-orange btn-xs btn-flat"><i className="fa fa-user-plus"></i></span> Approve Requests</h3>
                </div>
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Name</th>
                                <th>Business Name</th>
                                <th>Mobile</th>
                                {/* <th>City</th> */}
                                <th>Top Selling Product/Service</th>
                                <th>Actions</th>
                            </tr>
                            {incommingNetworks && incommingNetworks.map((network, index) => (
                                <tr key={index}>
                                    <td>{AutoSerialNumber(index, incommingNetworkPagination)}</td>
                                    <td>{network.name}</td>
                                    <td>{network.business_name}</td>
                                    <td>{network.mobile_number}</td>
                                    {/* <td>{network.city}</td> */}
                                    <td>{network.top_selling_product_service}</td>
                                    <td>
                                        <button title="accept" className="btn btn-success btn-xs btn-flat" onClick={handleIncomingAccept(network.network_id)}>
                                            <i className="fa fa-check"></i> APPROVE
                                        </button>
                                        &nbsp;| <button title="reject" className="btn btn-danger btn-xs btn-flat" onClick={handleIncomingDelete(network.network_id)}>
                                            <i className="fa fa-user-times"></i> REJECT
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="box-footer">
                    <Pagination
                        perPage={incommingNetworkPagination.per_page}
                        total={incommingNetworkPagination.total}
                        onPageChange={(selectedPageNumber) => {
                            retriveIncommingNetworksData(selectedPageNumber);
                        }}
                    />
                </div>
            </div>}

            {activeTab === 'outgoing.request' && <div className="box box-primary">
                <div className="box-header">
                    <h3 className="box-title"><span className="btn bg-purple btn-xs btn-flat"><i className="fa fa-history"></i></span> Awaiting Approval</h3>
                </div>
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Name</th>
                                <th>Business Name</th>
                                <th>Mobile</th>
                                {/* <th>City</th> */}
                                <th>Top Selling Product/Service</th>
                                <th>Actions</th>
                            </tr>
                            {outgoingNetworks && outgoingNetworks.map((network, index) => (
                                <tr key={index}>
                                    <td>{AutoSerialNumber(index, outgoingNetworkPagination)}</td>
                                    <td>{network.name}</td>
                                    <td>{network.business_name}</td>
                                    <td>{network.mobile_number}</td>
                                    {/* <td>{network.city}</td> */}
                                    <td>{network.top_selling_product_service}</td>
                                    <td>
                                        <button title="cancel" className="btn btn-danger btn-xs btn-flat" onClick={handleOutgoingDelete(network.network_id)}>
                                            <i className="fa fa-times"></i> CANCEL
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="box-footer">
                    <Pagination
                        perPage={outgoingNetworkPagination.per_page}
                        total={outgoingNetworkPagination.total}
                        onPageChange={(selectedPageNumber) => {
                            retriveOutgoingNetworksData(selectedPageNumber);
                        }}
                    />
                </div>
            </div>}
        </Layout>
    )
}

export default Network;