// export const API_URL = 'http://localhost:8000/api/';
export const API_URL = 'https://apiv2.beyondnetworking.in/api/';


const configURL = {
    activateAccount: `${API_URL}account-activation`,
    signUp: `${API_URL}signup`,
    signIn: `${API_URL}signin`,

    //---------------------------------------------------- businessCategories
    businessCategories: `${API_URL}business-categories`,
    createBusinessCategory: `${API_URL}business-categories/create`,

    //---------------------------------------------------- businessCategories
    businessCategoryVisitorExternal: `${API_URL}business-category-visitor-externals`,

    //---------------------------------------------------- chapters
    chapters: `${API_URL}chapters`,
    getChaptersByzone: `${API_URL}chapters/byzone`,
    createChapter: `${API_URL}chapters/create`,
    //---------------------------------------------------- users
    // users:`${API_URL}users`,
    // createUser:`${API_URL}user/create-user`,

    //---------------------------------------------------- members
    createMember: `${API_URL}members/create-member`,
    adminDashnboardInfo: `${API_URL}admin-dashnboard-info`,
    members: `${API_URL}members`,
    detailMember: `${API_URL}members`,
    updateMember: `${API_URL}members/post-update-member`,
    getAllMemberChapters: `${API_URL}members/get-all-chapters-member`,
    postChangeStatusMember: `${API_URL}members/post-change-status-member`,
    // postUpdateVisitor:`${API_URL}members/post-update-member`,
    addRole: `${API_URL}members/post-add-role`,
    removeRole: `${API_URL}members/post-remove-role`,

    //---------------------------------------------------- pincodes
    pincodeById: `${API_URL}pincodes`,
    pincodeGetData: `${API_URL}pincodes/get-data`,

    //---------------------------------------------------- roles
    roles: `${API_URL}roles`,
    createRole: `${API_URL}roles/create`,
    detailRoleAccessMatrix: `${API_URL}roles/get-access-matrix`,

    //---------------------------------------------------- visitors
    createVisitor: `${API_URL}visitors/create`,
    visitors: `${API_URL}visitors`,
    detailVisitor: `${API_URL}visitors`,
    postUpdateVisitor: `${API_URL}visitors/post-update`,

    //---------------------------------------------------- visitorsExternal
    createVisitorExternal: `${API_URL}visitorexternal/create`,
    visitorExternal: `${API_URL}visitorexternal`,

    //---------------------------------------------------- zones
    zones: `${API_URL}zones`,
    createZone: `${API_URL}zones/create`,

    //---------------------------------------------------- networks
    networks: `${API_URL}networks`,
    sendNetworkRequest: `${API_URL}networks/create-request`,
    acceptNetworkRequest: `${API_URL}networks/accept-request/`,
    rejectNetworkRequest: `${API_URL}networks/reject-request/`,

    //------------------------------------------------------ testimonials
    testimonials: `${API_URL}testimonial/person-testimonials`,
    writeTestimonial: `${API_URL}testimonial/write-testimonial`,
    approveTestimonial: `${API_URL}testimonial/approve-testimonial`,
    deleteTestimonial: `${API_URL}testimonial/delete-testimonial`,
    givenTestimonials: `${API_URL}testimonial/given-testimonials`,

    //------------------------------------------------------- messages
    sendMessage: `${API_URL}message/send-message`,
    myMessages: `${API_URL}message/my-messages`,
    myMessagesWithPerson: `${API_URL}message/my-messages-with-person`,
    readMessage: `${API_URL}message/read-message/`,
    deleteMessage: `${API_URL}message/delete-message/`,

    userStatuses: `${API_URL}user-status`,
    resetPassword: `${API_URL}reset-password`,
    changePassword: `${API_URL}change-password`,
    changePasswordByAdmin: `${API_URL}change-password-by-admin`,

    memberMeeting: {

        OneToOne: {
            Index: `${API_URL}member_one_to_one`,
            Create: `${API_URL}member_one_to_one/create`,
            View: `${API_URL}member_one_to_one/`,
            Update: `${API_URL}member_one_to_one/update/`,
            Delete: `${API_URL}member_one_to_one/`
        },

        Reference: {
            Index: `${API_URL}member_reference`,
            Create: `${API_URL}member_reference/create`,
            View: `${API_URL}member_reference/`,
            Update: `${API_URL}member_reference/update/`,
            Delete: `${API_URL}member_reference/`
        },

        BusinessClose: {
            Index: `${API_URL}member_business_close`,
            Create: `${API_URL}member_business_close/create`,
            View: `${API_URL}member_business_close/`,
            Update: `${API_URL}member_business_close/update/`,
            Delete: `${API_URL}member_business_close/`
        }
    },

    Meeting: {
        Index: `${API_URL}meeting`,
        Create: `${API_URL}meeting/create-meeting`,
        View: `${API_URL}meeting/`,
        Update: `${API_URL}meeting/update-meeting/`,
        Delete: `${API_URL}meeting/`,

        Members: {
            Index: `${API_URL}meeting-members/find-members`,
            Invite: `${API_URL}meeting-members/add-meeting-members`,
            Delete: `${API_URL}meeting-members/`,
            View: `${API_URL}meeting-members/`,
            Update: `${API_URL}meeting-members/update-member/`,
        },

        Visitors: {
            Index: `${API_URL}meeting-visitor/find-visitors`,
            Invite: `${API_URL}meeting-visitor/add-meeting-visitor`,
            Delete: `${API_URL}meeting-visitor/`,
            View: `${API_URL}meeting-visitor/`,
            Update: `${API_URL}meeting-visitor/update-member/`,
        },

        Category: {
            Index: `${API_URL}meeting-categories`,
        }
    },

    Point: {
        Index: `${API_URL}points`
    }

}

export default configURL;