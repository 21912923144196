import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../../layout/Layout";
import GivenTestimonial from "./GivenTestimonial";
import ListTestimonial from "./ListTestimonial";

const Testimonial = () => {

    let { id } = useParams();

    return (
        <Layout>
            <div className="col-md-12">
                <div className="nav-tabs-custom">
                    <ul className="nav nav-tabs text-bold">
                        <li className="active"><a href="#testimonials" data-toggle="tab">RECEIVED</a></li>
                        {(!id || JSON.parse(localStorage.getItem('user')).id == id)
                            && <li><a href="#testimonialsbyme" data-toggle="tab">GIVEN</a></li>}
                    </ul>
                    <div className="tab-content">
                        <div className="active tab-pane" id="testimonials">
                            <ListTestimonial id={id} />
                        </div>
                        <div className="tab-pane" id="testimonialsbyme">
                            <GivenTestimonial />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Testimonial;