import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";

import ScreenLoader from "../../layout/ScreenLoader";
import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";

import { useForm } from 'react-hook-form';
const DetailsVisitor = ({ history }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [chapters, setChapters] = useState([]);
    const [zones, setZones] = useState([]);
    const [businessCategories, setBusinessCategories] = useState([]);
    const [visitor, setVisitor] = useState([]);

    const [loader, setLoader] = useState(true);

    const urlParams = useParams();
    useEffect(() => {
        retrieveMembers();
        retrieveData();
    }, []);

    const retrieveMembers = () => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.detailVisitor}/${urlParams.id}`
        }).then(response => {
            setVisitor(response.data.data);
            Object.keys(response.data.data).forEach(key => {
                // console.log(key, obj[key]);
                setValue(key, response.data.data[key]);
            });
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    const retrieveData = () => {
        Axios({
            method: 'GET',
            url: `${configURL.zones}?per_page=10000`
        }).then(response => {
            setZones(response.data.data);
            // console.log(response.data.data);
        }).catch(e => {
            console.log(e);
        });

        Axios({
            method: 'GET',
            url: `${configURL.chapters}?per_page=10000`
            // url:`${configURL.getChaptersByzone}/${visitor.zone_id}`
        }).then(response => {
            setChapters(response.data.data);
            // console.log(response.data.data);
        }).catch(e => {
            console.log(e);
        });

        Axios({
            method: 'GET',
            url: `${configURL.businessCategories}?per_page=10000`
        }).then(response => {
            setBusinessCategories(response.data.data);
            // console.log(response.data.data);
        }).catch(e => {
            console.log(e);
        });
    };

    const handleChangeZone = (name) => (event) => {
        if (name == 'zone_id') {
            setLoader(true);
            Axios({
                method: 'GET',
                url: `${configURL.getChaptersByzone}/${event.target.value}`
            }).then(response => {
                setChapters(response.data.data);
                console.log(response.data);
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false);
            });
        }
    }

    const handleUpdateMember = (data) => {
        console.log(data);
        let lFormData = {};
        lFormData['name'] = data.name;
        lFormData['business_name'] = data.business_name;
        lFormData['business_category_ids'] = data.business_category_ids;
        // lFormData['occoupation'] = data.occoupation;
        lFormData['zone_id'] = data.zone_id;
        lFormData['chapter_id'] = data.chapter_id;
        lFormData['mobile_number'] = data.mobile_number;
        lFormData['email'] = data.email;
        lFormData['referred_by'] = data.referred_by;
        lFormData['first_visit'] = data.first_visit;
        lFormData['how_many_times_visited'] = data.how_many_times_visited;
        // console.log(lFormData);
        // return;
        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.postUpdateVisitor}/${urlParams.id}`,
            data: lFormData
        }).then(response => {
            console.log("UPDATE VISITOR RESPONSE", response);
            toast.success(response.data.message);
            history.push("/visitors");
        }).catch(error => {
            console.log('UPDATE VISITOR ERROR', error.response.data.error);
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        });
    };

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleUpdateMember)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="name" type="text" {...register('name', { required: 'required!' })} defaultValue={visitor.name} className="form-control" placeholder="Name" />
                        <InputError errors={errors} field={'name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('business_name', { required: 'required!' })} defaultValue={visitor.business_name} className="form-control" placeholder="Business Name" name="business_name" />
                        <InputError errors={errors} field={'business_name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Category <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('business_category_ids', { required: 'required!' })} defaultValue={visitor.business_category_ids}>
                            <option disabled selected>Select your option</option>
                            {businessCategories &&
                                businessCategories.map((business_cat, index) => (
                                    <option key={business_cat.id} value={business_cat.id}>
                                        {business_cat.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'business_category_ids'} />
                    </div>
                </div>
                {/* <div className="form-group">
                    <label className="col-sm-2 control-label">Occoupation <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="occoupation" type="text" {...register('occoupation', { required: 'required!' })} defaultValue={visitor.occoupation} className="form-control" placeholder="Occoupation" />
                        <InputError errors={errors} field={'occoupation'} />
                    </div>
                </div> */}
                <div className="form-group">
                    <label className="col-sm-2 control-label">Zone <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('zone_id', { required: 'required!' })} defaultValue={visitor.zone_id} onChange={handleChangeZone('zone_id')}>
                            <option disabled selected>Select your option</option>
                            {zones &&
                                zones.map((zone, index) => (
                                    <option key={index} value={zone.id}>
                                        {zone.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'zone_id'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Chapter <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('chapter_id', { required: 'required!' })} defaultValue={visitor.chapter_id}>
                            <option disabled selected>Select your option</option>
                            {chapters &&
                                chapters.map((chapter, index) => (
                                    <option key={index} value={chapter.id}>
                                        {chapter.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'chapter_id'} />
                    </div>
                </div>

                <div className="form-group">
                    <label className="col-sm-2 control-label">Mobile Number <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('mobile_number', { required: 'required!' })} defaultValue={visitor.mobile_number} className="form-control" placeholder="Mobile Number" name="mobile_number" />
                        <InputError errors={errors} field={'mobile_number'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Email <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="email" type="text" {...register('email', { required: 'required!' })} defaultValue={visitor.email} className="form-control" placeholder="Email" />
                        <InputError errors={errors} field={'email'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Referred By</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('referred_by')} defaultValue={visitor.referred_by} className="form-control" placeholder="Referred By" name="referred_by" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">First Visit</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('first_visit')} defaultValue={visitor.first_visit} className="form-control" placeholder="First Visit" name="first_visit" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">How Many Times Visited</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('how_many_times_visited')} defaultValue={visitor.how_many_times_visited} className="form-control" placeholder="How Many Times Visited" name="how_many_times_visited" />
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                    {/* <input type="submit" /> */}
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <ToastContainer />
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-user"></i></span> Detail Visitor</h3>
                    <div className="pull-right box-tools">
                        <Link to={`/visitors`} className="btn btn-primary btn-sm btn-flat"><i className="fa fa-arrow-left" title="Back"></i> Back to Visitors</Link>
                    </div>
                </div>
                {lForm()}
            </div>
        </Layout>
    )
}

export default DetailsVisitor;