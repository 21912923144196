import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";
import TimeAgo from "../../utility/TimeAgo"

import { useForm } from 'react-hook-form';
import ScreenLoader from "../../layout/ScreenLoader";
import ListTestimonial from "../testimonial/ListTestimonial";
const ViewMember = ({ history }) => {
    const { register, handleSubmit, setValue } = useForm();
    const [member, setMember] = useState([]);

    const [loader, setLoader] = useState(true);

    const urlParams = useParams();
    const [roles, setRoles] = useState([]);
    const [memberRoles, setMemberRoles] = useState([]);

    useEffect(() => {
        Axios({
            method: 'GET',
            url: `${configURL.roles}`
        }).then(response => {
            setRoles(response.data);
            retrieveMembers();
        }).catch(e => {
            console.log(e);
        });
    }, []);

    const retrieveMembers = () => {
        Axios({
            method: 'GET',
            url: `${configURL.detailMember}/${urlParams.id}`
        }).then(response => {
            if (!response.data.data) {
                alert('Member not found!')
                return;
            }
            setMember(response.data.data);

            Object.keys(response.data.data).forEach(key => {
                setValue(key, response.data.data[key]);
            });

            setMemberRoles(JSON.parse(response.data.data.user_role_ids));

        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    const lForm = () => {
        return (<div className="form-horizontal">
            <div className="box-body">
                {/* <div className="form-group">
                    <label className="col-sm-2 control-label">Occoupation</label>
                    <div className="col-sm-10">
                        <input name="occoupation" disabled type="text" {...register('occoupation')} defaultValue={member.occoupation} className="form-control" placeholder="Occoupation" />
                    </div>
                </div> */}
                <div className="form-group">
                    <label className="col-sm-2 control-label">Email</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.member_email} className="form-control" placeholder="Email" name="member_email" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Name</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('business_name')} defaultValue={member.business_name} className="form-control" placeholder="Business Name" name="business_name" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Phone</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('phone')} defaultValue={member.phone} className="form-control" placeholder="Phone" name="phone" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Mobile Number</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('mobile_number')} defaultValue={member.mobile_number} className="form-control" placeholder="Mobile Number" name="mobile_number" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Website</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('website')} defaultValue={member.website} className="form-control" placeholder="Website" name="website" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Describe Products Services</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('discribe_products_services')} defaultValue={member.discribe_products_services} className="form-control" name="discribe_products_services" placeholder="Describe Products Services" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Top Selling Product Service</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('top_selling_product_service')} defaultValue={member.top_selling_product_service} className="form-control" name="top_selling_product_service" placeholder="Top Selling Product Service" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Ideal Connection For Business</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('ideal_connection_for_business')} defaultValue={member.ideal_connection_for_business} className="form-control" name="ideal_connection_for_business" placeholder="Ideal Connection For Business" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Hobbies Interests</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('hobbies_interests')} defaultValue={member.hobbies_interests} className="form-control" name="hobbies_interests" placeholder="Hobbies Interests" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Education</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('education')} defaultValue={member.education} className="form-control" name="education" placeholder="Education" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Goal In Life</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('goal_in_life')} defaultValue={member.goal_in_life} className="form-control" name="goal_in_life" placeholder="Goal In Life" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Belief</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('belief')} defaultValue={member.belief} className="form-control" name="belief" placeholder="Belief" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Your Skills</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('your_skills')} defaultValue={member.your_skills} className="form-control" name="your_skills" placeholder="Your Skills" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">In Any Idara Khidmat</label>
                    <div className="col-sm-10">
                        <input type="text" disabled {...register('in_any_idara_khidmat')} defaultValue={member.in_any_idara_khidmat} className="form-control" name="in_any_idara_khidmat" placeholder="In Any Idara Khidmat" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Address</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" disabled {...register('address')} defaultValue={member.address} rows="3" name="address" placeholder="Address"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Pincode</label>
                    <div className="col-sm-3">
                        <div class="input-group">
                            <span class="input-group-addon"><img src="/dist/img/india.png" class="user-image input-group-addon-img" alt="User Image" /></span>
                            <input type="text" disabled {...register('type_pincode')} defaultValue={member.pincode} className="form-control" name="type_pincode" placeholder="Pincode" />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Anything You Would Like To Say About You</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" disabled {...register('anything_you_would_like_to_say_about_you')} defaultValue={member.anything_you_would_like_to_say_about_you} rows="3" name="anything_you_would_like_to_say_about_you" placeholder="Anything You Would Like To Say About You"></textarea>
                    </div>
                </div>
            </div>
        </div>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            {member.member_id && <div className="box box-danger">
                <section className="content">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="box">
                                <div className="box-body box-profile">
                                    <img className="profile-user-img img-responsive img-circle" src="../../dist/img/user.png" alt="User profile picture" />

                                    <h3 className="profile-username text-center">{member.user_name}</h3>

                                    <p className="text-center">
                                        {roles.filter((ele) => {
                                            return memberRoles.includes(ele.id);
                                        }).map((ele) => {
                                            return <span className="btn btn-primary btn-xs" style={{ margin: '2px' }}>{ele.display_title}</span>;
                                        })}
                                    </p>

                                    <p className="text-muted text-center">
                                        Joined @ {TimeAgo(member.user_createdAt)}
                                        {/* <br /><b className={member.bg_class}>{member.user_status_name}</b> */}
                                    </p>

                                    <ul className="list-group list-group-unbordered">
                                        <li className="list-group-item">
                                            <b>Chapter</b> <a className="pull-right">{member.chapter_display_title}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Zone</b> <a className="pull-right">{member.zone_display_title}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active"><a href="#details" data-toggle="tab">Details</a></li>
                                    <li><a href="#testimonials" data-toggle="tab">Testimonials</a></li>
                                    {/* <li><a href="#timeline" data-toggle="tab">Network</a></li> */}
                                </ul>
                                <div className="tab-content">
                                    <div className="active tab-pane" id="details">
                                        {lForm()}
                                        {/* <p>will display full details of this member</p> */}
                                    </div>
                                    <div className="tab-pane" id="testimonials">
                                        <ListTestimonial id={urlParams.id} />
                                    </div>

                                    {/* <div className="tab-pane" id="timeline">
                                        <p>will show internal network of this user</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
            </div>}
        </Layout>
    )
}

export default ViewMember;