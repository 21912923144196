import React from "react";

const Foolter = () => {
    return (<footer className="main-footer">

        <div className="pull-right hidden-xs">
            {/* Anything you want */}
        </div>
        <strong>Copyright &copy; 2022 <a href="#">BeyondNetworking</a>.</strong> All rights reserved.
    </footer>)
}

export default Foolter;