import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";
import TimeAgo from "../../utility/TimeAgo"

import { useForm } from 'react-hook-form';
import ScreenLoader from "../../layout/ScreenLoader";
import ListTestimonial from "../testimonial/ListTestimonial";
import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
const Profile = ({ history }) => {

    const getCurrentUserID = () => {
        const currentUser = JSON.parse(localStorage.getItem('user'));

        if (!currentUser) {
            return 0;
        }

        return currentUser.id;
    }

    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
    const [member, setMember] = useState([]);
    const [roles, setRoles] = useState([]);
    const [memberRoles, setMemberRoles] = useState([]);

    const [loader, setLoader] = useState(true);

    const urlParams = useParams();

    useEffect(() => {
        Axios({
            method: 'GET',
            url: `${configURL.roles}`
        }).then(response => {
            setRoles(response.data);
            retrieveMembers();
        }).catch(e => {
            console.log(e);
        });
    }, []);

    const retrieveMembers = () => {
        Axios({
            method: 'GET',
            url: `${configURL.detailMember}/${getCurrentUserID()}`
        }).then(response => {
            if (!response.data.data) {
                alert('Member not found!')
                return;
            }

            setMember(response.data.data);
            setMemberRoles(JSON.parse(response.data.data.user_role_ids));
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    const handleChangePassword = (formData) => {
        if (formData.password === formData.newpassword) {
            toast.error('New password is same as current password!');
            return;
        }

        if (formData.newpassword.length < 8) {
            toast.error('New password should contain minimum 8 characters!');
            return;
        }

        if (formData.newpassword !== formData.confirm_newpassword) {
            toast.error('Please enter confirm password same as new password!');
            return;
        }

        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.changePassword}`,
            data: {
                email: formData.email,
                password: formData.password,
                newpassword: formData.newpassword
            }
        }).then(response => {
            toast.success(response.data.message);
            document.getElementById('closeChangePasswordPopup').click();
        }).catch(error => {
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        });


    }

    const lForm = () => {
        return (<div className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Email</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.member_email} className="form-control" placeholder="Email" name="member_email" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Name</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.business_name} className="form-control" placeholder="Business Name" name="business_name" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Phone</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.phone} className="form-control" placeholder="Phone" name="phone" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Mobile Number</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.mobile_number} className="form-control" placeholder="Mobile Number" name="mobile_number" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Website</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.website} className="form-control" placeholder="Website" name="website" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Describe Products Services</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.discribe_products_services} className="form-control" name="discribe_products_services" placeholder="Describe Products Services" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Top Selling Product Service</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.top_selling_product_service} className="form-control" name="top_selling_product_service" placeholder="Top Selling Product Service" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Ideal Connection For Business</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.ideal_connection_for_business} className="form-control" name="ideal_connection_for_business" placeholder="Ideal Connection For Business" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Hobbies Interests</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.hobbies_interests} className="form-control" name="hobbies_interests" placeholder="Hobbies Interests" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Education</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.education} className="form-control" name="education" placeholder="Education" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Goal In Life</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.goal_in_life} className="form-control" name="goal_in_life" placeholder="Goal In Life" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Belief</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.belief} className="form-control" name="belief" placeholder="Belief" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Your Skills</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.your_skills} className="form-control" name="your_skills" placeholder="Your Skills" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">In Any Idara Khidmat</label>
                    <div className="col-sm-10">
                        <input type="text" disabled defaultValue={member.in_any_idara_khidmat} className="form-control" name="in_any_idara_khidmat" placeholder="In Any Idara Khidmat" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Address</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" disabled defaultValue={member.address} rows="3" name="address" placeholder="Address"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Pincode</label>
                    <div className="col-sm-3">
                        <div class="input-group">
                            <span class="input-group-addon"><img src="/dist/img/india.png" class="user-image input-group-addon-img" alt="User Image" /></span>
                            <input type="text" disabled defaultValue={member.pincode} className="form-control" name="type_pincode" placeholder="Pincode" />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Anything You Would Like To Say About You</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" disabled defaultValue={member.anything_you_would_like_to_say_about_you} rows="3" name="anything_you_would_like_to_say_about_you" placeholder="Anything You Would Like To Say About You"></textarea>
                    </div>
                </div>
            </div>
        </div>)
    }

    const changePasswordPopupForm = () => (
        <form onSubmit={handleSubmit(handleChangePassword)}>
            <div className="box-body">

                <div className="form-group">
                    <label className="col-sm-5 text-right control-label">Email <RequiredStar /></label>
                    <div className="col-sm-7">
                        <input type="email" {...register('email', { required: 'required!' })} className="form-control" placeholder="Email" name="email" />
                        <InputError errors={errors} field={'email'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-5 text-right control-label">Current Password <RequiredStar /></label>
                    <div className="col-sm-7">
                        <input type="password" {...register('password', { required: 'required!' })} className="form-control" placeholder="Current Password" name="password" />
                        <InputError errors={errors} field={'password'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-5 text-right control-label">New Password <br /><span style={{ fontSize: '0.8em', color: 'red' }}>(Minimum 8 characters)</span> <RequiredStar /></label>
                    <div className="col-sm-7">
                        <input type="password" {...register('newpassword', { required: 'required!' })} className="form-control" placeholder="New Password" name="newpassword" />
                        <InputError errors={errors} field={'newpassword'} />
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                    </div>
                    <label className="col-sm-5 text-right control-label">Confirm New Password <RequiredStar /></label>
                    <div className="col-sm-7">
                        <input type="password" {...register('confirm_newpassword', { required: 'required!' })} className="form-control" placeholder="Confirm New Password" name="confirm_newpassword" />
                        <InputError errors={errors} field={'confirm_newpassword'} />
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="col-sm-12">
                    <div className="pull-right">
                        <button type="submit" className="btn btn-danger btn-block btn-flat">Change Password</button>
                    </div>
                </div>
            </div>
        </form>
    )

    return (
        <Layout>
            {loader && <ScreenLoader />}
            {member.member_id && <div className="box box-danger">
                <section className="content">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="box">
                                <div className="box-body box-profile">
                                    <img className="profile-user-img img-responsive img-circle" src="../../dist/img/user.png" alt="User profile picture" />

                                    <h3 className="profile-username text-center">{member.user_name}</h3>

                                    <p className="text-center">
                                        {roles.filter((ele) => {
                                            return memberRoles.includes(ele.id);
                                        }).map((ele) => {
                                            return <span className="btn btn-primary btn-xs" style={{ margin: '2px' }}>{ele.display_title}</span>;
                                        })}
                                    </p>

                                    <p className="text-muted text-center">
                                        Joined @ {TimeAgo(member.user_createdAt)}
                                    </p>

                                    <ul className="list-group list-group-unbordered">
                                        <li className="list-group-item">
                                            <b>Chapter</b> <a className="pull-right">{member.chapter_display_title}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Zone</b> <a className="pull-right">{member.zone_display_title}</a>
                                        </li>
                                        <li className="list-group-item">
                                            <span
                                                data-toggle="modal" data-target="#change-password-popup"
                                                className="text-bold"
                                                onClick={() => reset({ email: '', password: '', newpassword: '', confirm_newpassword: '' })}
                                                style={{ cursor: 'pointer', color: '#CC0000' }}>Change password</span>
                                        </li>
                                        <li className="list-group-item">
                                            <Link to={`/edit-profile`} style={{ fontWeight: "bold", color: "#CC0000" }}>Edit profile</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                        <div className="col-md-9">
                            {lForm()}
                        </div>
                        <div class="modal fade text-center" id="change-password-popup" style={{ display: 'none' }}>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" id="closeChangePasswordPopup" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">x</span></button>
                                        <h4 class="modal-title">Change password</h4>
                                    </div>
                                    <div class="modal-body">
                                        {changePasswordPopupForm()}
                                    </div>
                                    <div class="modal-footer">
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                </section>
            </div>}
        </Layout>
    )
}

export default Profile;