import React from "react"
import { ThreeDots } from "react-loader-spinner"

function ScreenLoader() {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backdropFilter: 'blur(8px)',
            height: '100%',
            width: '100%',
            zIndex: '5000',
            position: 'fixed',
            top: 0,
            left: 0
        }}>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}

export default ScreenLoader