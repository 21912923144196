import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";
import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import useSearch from "../../utility/useSearch";
import Status from "../../utility/Status";

const BusinessCategories = ({ history }) => {
    const [businessCategories, setBusinessCategories] = useState([]);

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    const [loader, setLoader] = useState(true);

    const [search, Searchable] = useSearch();

    useEffect(() => {
        retrieveData({ selectedPageNumber: 1, search: '' });
    }, []);

    const retrieveData = ({ selectedPageNumber = 1, search = '' }) => {
        setLoader(true);

        Axios({
            method: 'GET',
            url: `${configURL.businessCategories}?current_page=${selectedPageNumber}&search=${search}`,
        }).then(response => {
            setBusinessCategories(response.data.data);
            setPagination(response.data.pagination);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    const handleDelete = (id) => (event) => {

        let text = "Are You sure want to delete? Data once deleteted cannot be recover!";

        if (window.confirm(text) == true) {
            setLoader(true);

            Axios({
                method: 'DELETE',
                url: `${configURL.businessCategories}/${id}`
            }).then(response => {
                toast.success(response.data.message);
                retrieveData();
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false);
            });
        }
    };

    return (
        <Layout>
            <div className="box box-primary">
                {loader && <ScreenLoader />}
                <ToastContainer />
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-briefcase"></i></span> Business Categories</h3>
                    <div className="box-tools pull-right" data-toggle="tooltip" title="" data-original-title="Status">
                        <div className="btn-group" data-toggle="btn-toggle">
                            <Link to="/create-business-category" className="btn btn-danger btn-sm btn-flat"><i>Add Business Category</i></Link>
                        </div>
                    </div>
                </div>
                {Searchable((search) => retrieveData({ search }))}
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Name</th>
                                <th>Display Name</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                            {businessCategories &&
                                businessCategories.map((businessCategory, index) => (
                                    <tr key={index}>
                                        <td>{AutoSerialNumber(index, pagination)}</td>
                                        <td>{businessCategory.name}</td>
                                        <td>{businessCategory.display_title}</td>
                                        <td>{<Status.Render status={businessCategory.active} />}</td>
                                        <td>
                                            <Link to={`/detail-business-category/${businessCategory.id}`} className="btn btn-primary btn-xs btn-flat"><i className="fa fa-list"></i></Link>
                                            &nbsp;| <button title="delete" className="btn btn-danger btn-xs btn-flat" onClick={handleDelete(businessCategory.id)}><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                </div>

                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            retrieveData({ selectedPageNumber, search });
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}


export default BusinessCategories;