import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import { useForm } from 'react-hook-form';
// import { isAuth } from "../../utility/Helper";

import LayoutExternal from "../../layout/LayoutExternal";

import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
import ScreenLoader from "../../layout/ScreenLoader";

const CreateVisitor = ({ history }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [businessCategories, setBusinessCategories] = useState([]);

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        Axios({
            method: 'GET',
            url: `${configURL.businessCategoryVisitorExternal}?per_page=10000`
        }).then(response => {
            setBusinessCategories(response.data.data);
            // console.log(response.data);
        }).catch(e => {
            console.log(e);
        });
    };

    const clickSubmit = (data) => {
        console.log(data);
        let lFormData = {};
        lFormData['name'] = data.name;
        lFormData['business_name'] = data.business_name;
        lFormData['business_category_ids'] = data.business_category_ids;
        // lFormData['occoupation'] = data.occoupation;
        // lFormData['chapter_id'] = data.chapter_id;
        lFormData['mobile_number'] = data.mobile_number;
        lFormData['email'] = data.email;
        // lFormData['referred_by'] = data.referred_by;
        // lFormData['first_visit'] = data.first_visit;
        // lFormData['how_many_times_visited'] = data.how_many_times_visited;
        // console.log(lFormData);
        // return;

        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.createVisitorExternal}`,
            data: lFormData
        }).then(response => {
            console.log("CREATE VISITOR RESPONSE", response);
            toast.success(response.data.message);
            history.push("/visitor-externals-thank-you");
        }).catch(error => {
            console.log('CREATE VISITOR ERROR', error.response.data.error);
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        });
    }

    const lForm = () => {
        return (<form onSubmit={handleSubmit(clickSubmit)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="name" type="text" {...register('name', { required: 'required!' })} className="form-control" placeholder="Name" />
                        <InputError errors={errors} field={'name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Email</label>
                    <div className="col-sm-10">
                        <input name="email" type="text" {...register('email')} className="form-control" placeholder="Email" />
                        <InputError errors={errors} field={'email'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Mobile Number <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('mobile_number', { required: 'required!' })} className="form-control" placeholder="Mobile Number" name="mobile_number" />
                        <InputError errors={errors} field={'mobile_number'} />
                    </div>
                </div>                                
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('business_name', { required: 'required!' })} className="form-control" placeholder="Business Name" name="business_name" />
                        <InputError errors={errors} field={'business_name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Category <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('business_category_ids', { required: 'required!' })} >
                            <option value="" disabled selected>Select your option</option>
                            {businessCategories &&
                                businessCategories.map((business_cat, index) => (
                                    <option key={index} value={business_cat.id}>
                                        {business_cat.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'business_category_ids'} />
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                    {/* <input type="submit" /> */}
                </div>
            </div>
        </form>)
    }

    return (
        <LayoutExternal>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-user"></i></span> Visitor Registration Form</h3>
                </div>
                <ToastContainer />
                {/* <div>{JSON.stringify({ name, email, chapter_id, business_category_ids, occoupation, business_name, phone, mobile_number, website, address, pincode, buttonText })}</div> */}
                {lForm()}

                {/* {!isAuth() ? <Redirect to="/signup" /> : null} */}
            </div>
        </LayoutExternal>
    )
}
export default CreateVisitor;