import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { Link, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";
import ScreenLoader from "../../layout/ScreenLoader";
import Axios from "../../utility/Axios";
import TimeAgo from "../../utility/TimeAgo"
import Chat from "./Chat";

const Messages = () => {

    const loggedUser = JSON.parse(localStorage.getItem('user'));

    const { toUserID } = useParams();

    const [selectedUserID, setSelectedUserID] = useState(0);

    const [list, setList] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        if (toUserID >= 1) {
            setSelectedUserID(toUserID);
        }

        Axios({
            method: 'POST',
            url: `${configURL.myMessages}`
        }).then((response) => {
            setList(response.data.data.reverse());
        }).finally(() => {
            setLoader(false);
        })
    }, []);

    // useEffect(() => {
    //     dispatch();
    // }, [selectedUserID])

    if (toUserID == loggedUser.id) {
        return (
            <Layout>
                <div>
                    <h2>Invalid action!</h2>
                </div>
            </Layout>
        )
    }

    const handleSelectMessage = (id) => (event) => {
        setSelectedUserID(id);
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-success">
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat">
                        <i className="fa fa-comment"></i></span> Messages
                    </h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="box box-solid">
                        <div className="box-header with-border bg-green">
                            <h3 className="box-title">Inbox</h3>
                        </div>
                        <div className="box-body no-padding" style={{ 'maxHeight': '58vh', 'overflowY': 'scroll' }}>
                            <ul className="nav nav-pills nav-stacked">
                                {list.length < 1 && <li>No message found!</li>}
                                {list.map((ele) => (
                                    <li style={{ 'cursor': 'pointer' }} onClick={handleSelectMessage(ele.message_user_id)}>
                                        <a><i className="fa fa-user"></i> {ele.message_user_name}
                                            {/* <span className="label label-primary pull-right">12</span> */}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* <div class="box box-solid">
                        <div class="box-header with-border">
                            <h3 class="box-title">Quick Actions</h3>
                        </div>

                        <div class="box-body no-padding">
                            <ul class="nav nav-pills nav-stacked">
                                <li><a href="#"><i class="fa fa-circle-o text-red"></i> Inbox</a></li>
                                <li><a href="#"><i class="fa fa-circle-o text-yellow"></i> Bin</a></li>
                                <li><a href="#"><i class="fa fa-circle-o text-light-blue"></i> Social</a></li>
                            </ul>
                        </div>

                    </div> */}
                </div>

                {selectedUserID >= 1 && <div className="col-md-8">
                    <Chat UserID={selectedUserID} />
                </div>}

            </div>
        </Layout>
    )
}

export default Messages;