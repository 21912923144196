import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";

import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import Status from "../../utility/Status";

const Roles = ({ history }) => {
    const [roles, setRoles] = useState([]);

    const [loader, setLoader] = useState(true);

    // No implementated here as Roles API does not have paginated data
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.roles}`,
        })
            .then(response => {
                setRoles(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false);
            })
    };

    // const handleDelete = (id) => (event) => {
    //     // alert(id);
    //     console.log(id);
    //     let text = "Are You sure want to delete? Data once deleteted cannot be recover!";
    //     if (window.confirm(text) == true) 
    //     {
    //         Axios({
    //             method: 'DELETE',
    //             url: `${configURL.roles}/${id}`
    //         })
    //             .then(response => {
    //                 toast.success(response.data.message);
    //                 retrieveData();
    //                 console.log(response.data);
    //             })
    //             .catch(e => {
    //                 console.log(e);
    //             });
    //     } 
    // };

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-primary">
                <ToastContainer />
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-user-secret"></i></span> Roles</h3>
                    <div className="box-tools pull-right" data-toggle="tooltip" title="" data-original-title="Status">
                        <div className="btn-group" data-toggle="btn-toggle">
                            <Link to="/create-role" className="btn btn-danger btn-sm btn-flat"><i>Add Role</i></Link>
                        </div>
                    </div>
                </div>
                <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Code</th>
                                <th>Display Name</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                            {roles &&
                                roles.map((role, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{role.code}</td>
                                        <td>{role.display_title}</td>
                                        <td><Status.Render status={role.active}/></td>
                                        <td>
                                            <Link to={`/detail-role/${role.id}`} className="btn btn-primary btn-xs btn-flat"><i className="fa fa-list"></i></Link>
                                            {/* &nbsp;| <Link to={`/detail-role-access-matrix/${role.id}`} className="btn btn-info btn-xs btn-flat">Access Matrix</Link> */}
                                        </td>
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    )
}

export default Roles;