import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import { useForm } from 'react-hook-form';
// import { isAuth } from "../../utility/Helper";

import Layout from "../../layout/Layout";
import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
import ScreenLoader from "../../layout/ScreenLoader";

const CreateMember = ({ history }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [chapters, setChapters] = useState([]);
    const [pincodes, setPincodes] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [zones, setZones] = useState([]);
    const [businessCategories, setBusinessCategories] = useState([]);

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        Axios({
            method: 'GET',
            url: `${configURL.zones}?per_page=10000`
        }).then(response => {
            setZones(response.data.data);
        }).catch(e => {
            console.log(e);
        });

        Axios({
            method: 'GET',
            url: `${configURL.businessCategories}?per_page=10000`
        }).then(response => {
            setBusinessCategories(response.data.data);
        }).catch(e => {
            console.log(e);
        });
    };

    const handleChangeZone = (name) => (event) => {
        if (name != 'zone_id') {
            return;
        }

        setLoader(true);

        Axios({
            method: 'GET',
            url: `${configURL.getChaptersByzone}/${event.target.value}`
        }).then(response => {
            setChapters(response.data.data);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    }

    const handlePincode = (name) => (event) => {
        const lPincode = event.target.value;

        if (lPincode.length == 6) {
            Axios({
                method: 'GET',
                url: `${configURL.pincodeGetData}/${lPincode}`
            }).then(response => {
                setPincodes(response.data.data);
                setCities(response.data.cities);
                setStates(response.data.states);
            }).catch(e => {
                console.log(e);
            });
        }
    }

    const clickSubmit = (data) => {
        console.log(data);

        setLoader(true);

        const lPincode = pincodes.find(o => o.id == data.pincode);

        console.log(data.pincode, lPincode);

        let lFormData = {};
        lFormData['name'] = data.name;
        lFormData['email'] = data.email;
        lFormData['chapter_id'] = data.chapter_id;
        lFormData['business_category_ids'] = data.business_category_ids;
        // lFormData['occoupation'] = data.occoupation;
        lFormData['business_name'] = data.business_name;
        lFormData['phone'] = data.phone;
        lFormData['mobile_number'] = data.mobile_number;
        lFormData['website'] = data.website;
        lFormData['discribe_products_services'] = data.discribe_products_services;
        lFormData['top_selling_product_service'] = data.top_selling_product_service;
        lFormData['ideal_connection_for_business'] = data.ideal_connection_for_business;
        lFormData['hobbies_interests'] = data.hobbies_interests;
        lFormData['education'] = data.education;
        lFormData['goal_in_life'] = data.goal_in_life;
        lFormData['belief'] = data.belief;
        lFormData['your_skills'] = data.your_skills;
        lFormData['in_any_idara_khidmat'] = data.in_any_idara_khidmat;
        lFormData['address'] = data.address;
        lFormData['city'] = lPincode ? lPincode.village : null;
        lFormData['state'] = lPincode ? lPincode.state : null;
        lFormData['country'] = lPincode ? lPincode.country : null;
        lFormData['pincode'] = lPincode ? lPincode.pincode : null;
        lFormData['pincode_id'] = lPincode ? lPincode.id : null;
        lFormData['anything_you_would_like_to_say_about_you'] = data.anything_you_would_like_to_say_about_you;

        Axios({
            method: 'POST',
            url: `${configURL.createMember}`,
            data: lFormData
        }).then(response => {
            toast.success(response.data.message);
            history.push("/members");
        }).catch(error => {
            toast.error(error.response.data.error);
        }).finally(() => {
            setLoader(false);
        });
    }

    const lForm = () => {
        return (<form onSubmit={handleSubmit(clickSubmit)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">
                        Name <RequiredStar />
                    </label>
                    <div className="col-sm-10">
                        <input name="name" type="text" {...register('name', { required: 'required!' })} className="form-control" placeholder="Name" />
                        <InputError errors={errors} field={'name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Email <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input name="email" type="text" {...register('email', { required: 'required!' })} className="form-control" placeholder="Email" />
                        <InputError errors={errors} field={'email'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Zone <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('zone_id', { required: 'required!' })} onChange={handleChangeZone('zone_id')}>
                            <option value="" disabled selected>Select your option</option>
                            {zones &&
                                zones.map((zone, index) => (
                                    <option key={index} value={zone.id}>
                                        {zone.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'zone_id'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Chapter <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('chapter_id', { required: 'required!' })}>
                            <option value="" disabled selected>Select your option</option>
                            {chapters &&
                                chapters.map((chapter, index) => (
                                    <option key={index} value={chapter.id}>
                                        {chapter.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'chapter_id'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Category <RequiredStar /></label>
                    <div className="col-sm-10">
                        <select className="form-control" {...register('business_category_ids', { required: 'required!' })}>
                            <option value="" disabled selected>Select your option</option>
                            {businessCategories &&
                                businessCategories.map((business_cat, index) => (
                                    <option key={index} value={business_cat.id}>
                                        {business_cat.display_title}
                                    </option>
                                ))}
                        </select>
                        <InputError errors={errors} field={'business_category_ids'} />
                    </div>
                </div>
                {/* <div className="form-group">
                    <label className="col-sm-2 control-label">Occoupation</label>
                    <div className="col-sm-10">
                        <input name="occoupation" type="text" {...register('occoupation')} className="form-control" placeholder="Occoupation" />
                    </div>
                </div> */}
                <div className="form-group">
                    <label className="col-sm-2 control-label">Business Name <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('business_name', { required: 'required!' })} className="form-control" placeholder="Business Name" name="business_name" />
                        <InputError errors={errors} field={'business_name'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Phone</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('phone')} className="form-control" placeholder="Phone" name="phone" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Mobile Number <RequiredStar /></label>
                    <div className="col-sm-10">
                        <input type="text" {...register('mobile_number', { required: 'required!' })} className="form-control" placeholder="Mobile Number" name="mobile_number" />
                        <InputError errors={errors} field={'mobile_number'} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Website</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('website')} className="form-control" placeholder="Website" name="website" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Describe Products Services</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('discribe_products_services')} className="form-control" name="discribe_products_services" placeholder="Describe Products Services" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Top Selling Product Service</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('top_selling_product_service')} className="form-control" name="top_selling_product_service" placeholder="Top Selling Product Service" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Ideal Connection For Business</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('ideal_connection_for_business')} className="form-control" name="ideal_connection_for_business" placeholder="Ideal Connection For Business" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Hobbies Interests</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('hobbies_interests')} className="form-control" name="hobbies_interests" placeholder="Hobbies Interests" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Education</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('education')} className="form-control" name="education" placeholder="Education" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Goal In Life</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('goal_in_life')} className="form-control" name="goal_in_life" placeholder="Goal In Life" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Belief</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('belief')} className="form-control" name="belief" placeholder="Belief" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Your Skills</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('your_skills')} className="form-control" name="your_skills" placeholder="Your Skills" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">In Any Idara Khidmat</label>
                    <div className="col-sm-10">
                        <input type="text" {...register('in_any_idara_khidmat')} className="form-control" name="in_any_idara_khidmat" placeholder="In Any Idara Khidmat" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Address</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" {...register('address')} rows="3" name="address" placeholder="Address"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Pincode</label>
                    <div className="col-sm-2">
                        <div class="input-group">
                            <span class="input-group-addon"><img src="/dist/img/india.png" class="user-image input-group-addon-img" alt="User Image" /></span>
                            <input type="text" {...register('type_pincode')} className="form-control" name="type_pincode" placeholder="Pincode" onChange={handlePincode()} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div class="input-group">
                            <span class="input-group-addon">State</span>
                            <select className="form-control" {...register('state')} >
                                <option value="" disabled selected>State</option>
                                {states &&
                                    states.map((state, index) => (
                                        <option key={index} value={state}>
                                            {state}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div class="input-group">
                            <span class="input-group-addon">City</span>
                            <select className="form-control" {...register('pincode')} >
                                <option value="" disabled selected>City</option>
                                {pincodes &&
                                    pincodes.map((pincode, index) => (
                                        <option key={index} value={pincode.id}>
                                            {pincode.village}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Anything You Would Like To Say About You</label>
                    <div className="col-sm-10">
                        <textarea className="form-control" {...register('anything_you_would_like_to_say_about_you')} rows="3" name="anything_you_would_like_to_say_about_you" placeholder="Anything You Would Like To Say About You"></textarea>
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                    {/* <input type="submit" /> */}
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-users"></i></span> Add Member</h3>
                    <div class="pull-right box-tools">
                        <Link to={`/members`} className="btn btn-primary btn-sm btn-flat"><i className="fa fa-arrow-left" title="Back"></i> Back to Members</Link>
                    </div>
                </div>
                <ToastContainer />
                {/* <div>{JSON.stringify({ name, email, chapter_id, business_category_ids, occoupation, business_name, phone, mobile_number, website, address, pincode, buttonText })}</div> */}
                {lForm()}

                {/* {!isAuth() ? <Redirect to="/signup" /> : null} */}
            </div>
        </Layout>
    )
}
export default CreateMember;