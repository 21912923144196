import React, { createContext } from "react";
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import App from '../App';

//------------------------------------------ businessCategories
import createBusinessCategory from '../pages/businessCategory/CreateBusinessCategory';
import BusinessCategory from '../pages/businessCategory/BusinessCategories';
import DetailsBusinessCategory from '../pages/businessCategory/DetailsBusinessCategory';

// //------------------------------------------ chapters
import CreateChapter from '../pages/chapter/CreateChapter';
import Chapter from '../pages/chapter/Chapters';
import DetailsChapter from '../pages/chapter/DetailsChapter';

//--------------------------------------------- dashboard
import AdminDashboard from "../pages/dashboard/AdminDashboard";

//------------------------------------------ members
import CreateMember from '../pages/member/CreateMember';
import Member from '../pages/member/Members';
import DetailsMember from '../pages/member/DetailsMember';

//------------------------------------------ roles
import CreateRole from '../pages/role/CreateRole';
import Role from '../pages/role/Roles';
import DetailsRole from '../pages/role/DetailsRole';
import DetailsRoleAccessMatrix from '../pages/role/DetailsRoleAccessMatrix';

//------------------------------------------ Zones
import CreateZone from '../pages/zone/CreateZone';
import Zone from '../pages/zone/Zones';
import DetailsZone from '../pages/zone/DetailsZone';

//------------------------------------------ Visitors
import CreateVisitor from '../pages/visitor/CreateVisitor';
import Visitor from '../pages/visitor/Visitors';
import DetailsVisitor from '../pages/visitor/DetailsVisitor';

//------------------------------------------ VisitorExternal
import CreateVisitorExternal from '../pages/visitorexternal/CreateVisitorExternal';
import VisitorExternal from '../pages/visitorexternal/VisitorExternals';
import VisitorExternalThankYou from '../pages/visitorexternal/VisitorExternalThankYou';

// import Activate from '../pages/auth/Activate';

// import Role from "../pages/Role";
// import SignUp from "../pages/auth/SignUp";
import SignIn from "../pages/auth/SignIn";
import PrivateRouter from "./PrivateRouter";

import Network from "../pages/network/Network";
import AddNetwork from "../pages/network/AddNetwork";
import Testimonial from "../pages/testimonial/Testimonial";
import ViewMember from "../pages/member/ViewMember";
import Messages from "../pages/message/Messages";
import BoardMemebers from "../pages/member/BoardMemebers";
import Profile from "../pages/member/Profile";
import EditProfile from "../pages/member/EditProfile";
import NetworkMeeting from "../pages/networkMeeting/NetworkMeeting";
import ViewNetworkMeeting from "../pages/networkMeeting/ViewNetworkMeeting";
import Meetings from "../pages/meeting/Meetings";
import CreateMeeting from "../pages/meeting/CreateMeeting";
import ViewMeeting from "../pages/meeting/ViewMeeting";
import AddMeetingMembers from "../pages/meeting/AddMeetingMembers";
import AddMeetingVisitors from "../pages/meeting/AddMeetingVisitors";
import EditMeetingMember from "../pages/meeting/EditMeetingMember";
import EditMeetingVisitor from "../pages/meeting/EditMeetingVisitor";
import Point from "../pages/point/Point";

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <PrivateRouter exact path="/"  component={AdminDashboard} />
                <PrivateRouter exact path="/admin-dashboard"  component={AdminDashboard} />
                
                <PrivateRouter exact path="/business-categories"  component={BusinessCategory} />
                <PrivateRouter exact path="/detail-business-category/:id"  component={DetailsBusinessCategory} />
                <PrivateRouter exact path="/create-business-category"  component={createBusinessCategory} />

                <PrivateRouter exact path="/chapters/:zone_id"  component={Chapter} />
                <PrivateRouter exact path="/detail-chapter/:id"  component={DetailsChapter} />
                <PrivateRouter exact path="/create-chapter/:zone_id"  component={CreateChapter} />

                <PrivateRouter exact path="/members"  component={Member} />
                <PrivateRouter exact path="/board-members"  component={BoardMemebers} />
                <PrivateRouter exact path="/detail-member/:id"  component={DetailsMember} />
                <PrivateRouter exact path="/create-member"  component={CreateMember} />
                <PrivateRouter exact path="/view-member/:id"  component={ViewMember} />
                <PrivateRouter exact path="/profile"  component={Profile} />
                <PrivateRouter exact path="/edit-profile"  component={EditProfile} />

                <PrivateRouter exact path="/roles"  component={Role} />
                <PrivateRouter exact path="/detail-role/:id"  component={DetailsRole} />
                <PrivateRouter exact path="/detail-role-access-matrix/:id"  component={DetailsRoleAccessMatrix} />
                <PrivateRouter exact path="/create-role"  component={CreateRole} />      

                <PrivateRouter exact path="/visitors"  component={Visitor} />
                <PrivateRouter exact path="/detail-visitor/:id"  component={DetailsVisitor} />
                <PrivateRouter exact path="/create-visitor"  component={CreateVisitor} />

                <PrivateRouter exact path="/visitor-external"  component={VisitorExternal} />
                <Route exact path="/create-visitor-externals"  component={CreateVisitorExternal} /> 
                <Route exact path="/visitor-externals-thank-you"  component={VisitorExternalThankYou} />                

                <PrivateRouter exact path="/network-meetings"  component={NetworkMeeting} />
                {/* <PrivateRouter exact path="/network-meetings/view/:meetingtype/:id"  component={ViewNetworkMeeting} /> */}

                <PrivateRouter exact path="/zones"  component={Zone} />
                <PrivateRouter exact path="/detail-zone/:id"  component={DetailsZone} />
                <PrivateRouter exact path="/create-zone"  component={CreateZone} />

                <PrivateRouter exact path="/networks" component={Network} />
                <PrivateRouter exact path="/add-network" component={AddNetwork} />

                <PrivateRouter exact path="/testimonials" component={Testimonial} />
                <PrivateRouter exact path="/testimonials/:id" component={Testimonial} />

                <PrivateRouter exact path="/messages" component={Messages} />
                <PrivateRouter exact path="/messages/:toUserID" component={Messages} />

                <PrivateRouter exact path="/meetings" component={Meetings} />
                <PrivateRouter exact path="/meetings/create/:meeting_category_id" component={CreateMeeting} />
                <PrivateRouter exact path="/meetings/view/:id" component={ViewMeeting} />
                <PrivateRouter exact path="/meetings/members/add/:id" component={AddMeetingMembers} />
                <PrivateRouter exact path="/meetings/visitors/add/:id" component={AddMeetingVisitors} />
                <PrivateRouter exact path="/meetings/members/edit/:meeting_id/:invitation_id" component={EditMeetingMember} />
                <PrivateRouter exact path="/meetings/visitors/edit/:meeting_id/:invitation_id" component={EditMeetingVisitor} />

                <PrivateRouter exact path="/points" component={Point} />

                {/* <PrivateRouter exact path="/users"  component={ListUsers} /> */}
                {/* <Route exact path="/auth/activate"  component={Activate} /> */}
                {/* <PrivateRouter exact path="/role"  component={Role} /> */}
                {/* <Route exact path="/signup"  component={SignUp} /> */}
                <Route exact path="/signin"  component={SignIn} />
            </Switch>
        </BrowserRouter>
    )
}

export default Router;