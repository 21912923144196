import React from "react";
import { useState } from "react";

function Pagination({ perPage = 5, total, onPageChange }) {

    const [currentPage, setCurrentPage] = useState(1);

    if (!total) {
        return (<div></div>);
    }

    const handlePageChange = (selectedPage) => (event) => {

        if (selectedPage == currentPage) {
            return;
        }

        setCurrentPage(selectedPage);
        onPageChange(selectedPage);
    }

    const links = Array(Math.ceil(total / perPage)).fill(0).map((ele, index) => {
        return (
            <div key={index} onClick={handlePageChange(index + 1)}
                style={{
                    padding: '6px 10px',
                    fontWeight: 'bold',
                    backgroundColor: (index + 1) == currentPage ? 'gray' : 'white',
                    // margin: '5px',
                    // borderRadius: '5px',
                    width: '32px',
                    height: '32px',
                    border: '1px solid #CCC',
                    color: (index + 1) == currentPage ? 'white' : 'gray',
                    cursor: (index + 1) == currentPage ? 'not-allowed' : 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                {index + 1}
            </div>
        );
    });

    if (links.length < 2) {
        return (<div></div>);
    }

    return (
        <div
            style={{
                // border: '1px solid #CCC',
                borderRadius: '5px',
                padding: '15px',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '5px 0px'
            }}>
            {links}
        </div>
    );
}

export default Pagination;