import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";
import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import TimeAgo from "../../utility/TimeAgo"
import { useForm } from "react-hook-form";

const NetworkMeeting = () => {
    const [activeTab, setActiveTab] = useState('OneToOne');

    const [rows, setRows] = useState([]);

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    const [zones, setZones] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [params, setParams] = useState({});

    const { register, handleSubmit } = useForm();

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        // retriveZones();
        retrieveRows();
    }, []);

    useEffect(() => {
        retrieveRows();
    }, [activeTab]);

    const retriveZones = () => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.zones}?per_page=10000`
        }).then(response => {
            setZones(response.data.data);
        }).catch(e => {
            console.log(e);
        }).finally(function () {
            setLoader(false);
        });
    };

    const retrieveRows = (selectedPageNumber = 1) => {
        setLoader(true);

        Axios({
            method: 'GET',
            url: configURL.memberMeeting[activeTab].Index + '?current_page=' + selectedPageNumber
        }).then(response => {
            setRows(response.data.data);
            setPagination(response.data.pagination);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    const search = function (params, selectedPageNumber = 1) {
        setLoader(true);
        setParams({});

        let search_field = params.search_field || '';

        Axios({
            method: 'POST',
            data: params,
            url: configURL.memberMeeting[activeTab].Index + '?current_page=' + selectedPageNumber + '&search=' + search_field
        }).then(response => {
            setRows(response.data.data);
            setPagination(response.data.pagination);
            setParams(params);
        }).catch(e => {
            setRows([]);
        }).finally(() => {
            setLoader(false);
        });
    }

    const handleSearch = (params) => {
        search(params);
    };

    const handleChangeZone = () => (event) => {

        if (!event.target.value) {
            setChapters([]);
            return;
        }
        setLoader(true);

        Axios({
            method: 'GET',
            url: `${configURL.getChaptersByzone}/${event.target.value}`
        }).then(response => {
            setChapters(response.data.data);
        }).catch(e => {
            console.log(e);
        }).finally(function () {
            setLoader(false);
        });
    }

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleSearch)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <div className="col-sm-3">
                        <select placeholder="select zone here" className="form-control" {...register('zone_id')} onChange={handleChangeZone()} title="Zone">
                            <option value="" selected>Select zone</option>
                            {zones && zones.map((zone, index) => (
                                <option key={index} value={zone.id}>
                                    {zone.display_title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-4">
                        <select className="form-control" {...register('chapter_id')} title="Chapter">
                            <option value="" selected>Select chapter</option>
                            {chapters && chapters.map((chapter, index) => (
                                <option key={index} value={chapter.id}>
                                    {chapter.display_title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-sm-4">
                        <input type="text" {...register('search_field')} className="form-control" placeholder="search..." name="search_field" title="search..." />
                    </div>
                    <div className="col-sm-1">
                        <div className="pull-left">
                            <button type="submit" className="btn btn-success btn-block btn-flat">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-info">
                <div className="box-header">
                    {/* <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-user"></i></span> Network Meetings</h3> */}
                </div>
                {/* {lForm()} */}
                <div className="row" style={{ marginLeft: '10px' }}>
                    <div className="col-lg-3 col-xs-6" onClick={() => setActiveTab('OneToOne')} style={{ cursor: 'pointer' }}>
                        <div className={activeTab === 'OneToOne' ? 'small-box bg-green' : 'small-box bg-gray'}>
                            <div className="small-box-footer text-bold">One-to-One Meetings</div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-xs-6" onClick={() => setActiveTab('Reference')} style={{ cursor: 'pointer' }}>
                        <div className={activeTab === 'Reference' ? 'small-box bg-orange' : 'small-box bg-gray'}>
                            <div href="#" className="small-box-footer text-bold">Reference Meetings</div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-xs-6" onClick={() => setActiveTab('BusinessClose')} style={{ cursor: 'pointer' }}>
                        <div className={activeTab === 'BusinessClose' ? 'small-box bg-purple' : 'small-box bg-gray'}>
                            <div href="#" className="small-box-footer text-bold">Business Close Meetings</div>
                        </div>
                    </div>
                </div>
                {rows.length < 1 && <div className="text-center">
                    No data found!
                </div>}
                {rows.length > 0 && <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr. No.</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Name</th>
                                <th>Comment</th>
                                {activeTab == 'BusinessClose' && <th>Amount</th>}
                                {/* <th>Actions</th> */}
                            </tr>
                            {rows && rows.map((row, index) => (
                                <tr key={index}>
                                    <td>{AutoSerialNumber(index, pagination)}</td>
                                    <td>{row.from_user_name}</td>
                                    <td>{row.to_user_name}</td>
                                    <td>{row.name}</td>
                                    <td>{row.comment}</td>
                                    {activeTab == 'BusinessClose' && <td>{row.amount}</td>}
                                    {/* <td>
                                        <Link to={`/network-meetings/view/${activeTab}/${row.id}`} className="btn btn-success btn-xs btn-flat">
                                            <i className="fa fa-list"></i>
                                        </Link>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}

                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            search(params, selectedPageNumber);
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default NetworkMeeting;