import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
// import { isAuth } from "../../utility/Helper";
import Layout from "../../layout/Layout";

import Pagination from "../../utility/pagination/Pagination";
import ScreenLoader from "../../layout/ScreenLoader";
import AutoSerialNumber from "../../utility/pagination/SerialNumber";
import TimeAgo from "../../utility/TimeAgo"
import { useForm } from "react-hook-form";

const VisitorExternal = () => {

    const [visitors, setVisitors] = useState([]);

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 10,
        current_page: 1
    });

    // const [zones, setZones] = useState([]);
    // const [chapters, setChapters] = useState([]);
    // const [businessCategories, setBusinessCategories] = useState([]);
    const [params, setParams] = useState({});

    const { register, handleSubmit } = useForm();

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        retrieveVisitors();
        // retrieveData();
    }, []);

    const retrieveVisitors = (selectedPageNumber = 1) => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.visitorExternal}?current_page=${selectedPageNumber}`
        }).then(response => {
            setVisitors(response.data.data);
            setPagination(response.data.pagination);
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoader(false);
        });
    };

    // const retrieveData = () => {
        // setLoader(true);
        // Axios({
        //     method: 'GET',
        //     url: `${configURL.zones}?per_page=10000`
        // }).then(response => {
        //     setZones(response.data.data);
        // }).catch(e => {
        //     console.log(e);
        // }).finally(function () {
        //     setLoader(false);
        // });

        // Axios({
        //     method: 'GET',
        //     url: `${configURL.businessCategories}?per_page=10000`
        // }).then(response => {
        //     setBusinessCategories(response.data.data);
        // }).catch(e => {
        //     console.log(e);
        // });
    // };

    const search = function (params, selectedPageNumber = 1) {
        setLoader(true);
        setParams({});

        let search_field = params.search_field || '';

        Axios({
            method: 'POST',
            data: params,
            url: `${configURL.visitorExternal}?current_page=${selectedPageNumber}&search=${search_field}`
        }).then(response => {
            setVisitors(response.data.data);
            setPagination(response.data.pagination);
            setParams(params);
        }).catch(e => {
            setVisitors([]);
        }).finally(() => {
            setLoader(false);
        });
    }

    const handleDelete = (id) => (event) => {

        let text = "Are You sure want to delete? Data once deleteted cannot be recover!";

        if (window.confirm(text) == true) {
            setLoader(true);

            Axios({
                method: 'DELETE',
                url: `${configURL.visitorExternal}/${id}`
            }).then(response => {
                // toast.success(response.data.message);
                retrieveVisitors();
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                setLoader(false);
            });
        }
    };

    const handleSearch = (params) => {
        search(params);
    };

    // const handleChangeZone = () => (event) => {

    //     if (!event.target.value) {
    //         setChapters([]);
    //         return;
    //     }
    //     setLoader(true);

    //     Axios({
    //         method: 'GET',
    //         url: `${configURL.getChaptersByzone}/${event.target.value}`
    //     }).then(response => {
    //         setChapters(response.data.data);
    //     }).catch(e => {
    //         console.log(e);
    //     }).finally(function () {
    //         setLoader(false);
    //     });
    // }

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleSearch)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    {/* <div className="col-sm-3">
                        <select placeholder="select zone here" className="form-control" {...register('zone_id')} onChange={handleChangeZone()} title="Zone">
                            <option value="" selected>Select zone</option>
                            {zones && zones.map((zone, index) => (
                                <option key={index} value={zone.id}>
                                    {zone.display_title}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    {/* <div className="col-sm-4">
                        <select className="form-control" {...register('chapter_id')} title="Chapter">
                            <option value="" selected>Select chapter</option>
                            {chapters && chapters.map((chapter, index) => (
                                <option key={index} value={chapter.id}>
                                    {chapter.display_title}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    {/* <div className="col-sm-2">
                        <select className="form-control" {...register('business_category_id')} title="Business Category">
                            <option value="" selected>Select business category</option>
                            {businessCategories && businessCategories.map((businessCategory, index) => (
                                <option key={index} value={businessCategory.id}>
                                    {businessCategory.display_title}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div className="col-sm-4">
                        <input type="text" {...register('search_field')} className="form-control" placeholder="search by name, email, mobile..." name="search_field" title="search by name, email, mobile..." />
                    </div>
                    <div className="col-sm-1">
                        <div className="pull-left">
                            <button type="submit" className="btn btn-success btn-block btn-flat">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-info">
                <div className="box-header">
                    <h3 className="box-title"><span className="btn btn-success btn-xs btn-flat"><i className="fa fa-user"></i></span> Visitors</h3>
                    {/* <div className="box-tools pull-right" data-toggle="tooltip" title="" data-original-title="Status">
                        <div className="btn-group" data-toggle="btn-toggle">
                            <Link to="/create-visitor" className="btn btn-danger btn-sm btn-flat"><i>Add Visitor</i></Link>
                        </div>
                    </div> */}
                </div>
                {lForm()}
                <br />
                {visitors.length < 1 && <div className="text-center">
                    No data found!
                </div>}
                {visitors.length > 0 && <div className="box-body table-responsive no-padding">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <th>Sr.no.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                {/* <th>Zone</th>
                                <th>Chapter</th> */}
                                <th>Business</th>
                                <th>Business Category</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                            {visitors &&
                                visitors.map((visitor, index) => (
                                    <tr key={index}>
                                        <td>{AutoSerialNumber(index, pagination)}</td>
                                        <td>{visitor.name}</td>
                                        <td>{visitor.email}</td>
                                        <td>{visitor.mobile_number}</td>
                                        {/* <td>{visitor.zone}</td>
                                        <td>{visitor.chapter}</td> */}
                                        <td>{visitor.business_name}</td>
                                        <td>{visitor.business_category_name || '-'}</td>
                                        <td>{TimeAgo(visitor.createdAt)}</td>
                                        <td>
                                            {/* <Link to={`/detail-visitor/${visitor.id}`} className="btn btn-danger btn-xs btn-flat"><i className="fa fa-list"></i></Link> */}
                                            <button title="delete" className="btn btn-danger btn-xs btn-flat" onClick={handleDelete(visitor.id)}><i className="fa fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                </div>}

                <div className="box-footer">
                    <Pagination
                        perPage={pagination.per_page}
                        total={pagination.total}
                        onPageChange={(selectedPageNumber) => {
                            search(params, selectedPageNumber);
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default VisitorExternal;