import React from 'react';
import { isAuth, signout } from '../utility/Helper';
import createBrowserHistory from 'history/createBrowserHistory';
import TimeAgo from "../utility/TimeAgo"
import { Link } from 'react-router-dom';
const history = createBrowserHistory();
const HeaderExternal = () => {
  return (<header className="main-header">
    <a href="index2.html" className="logo">
      <span className="logo-mini"><b>B</b>N</span>
      <span className="logo-lg"><b>BeyondNetworking</b></span>
    </a>
    <nav className="navbar navbar-static-top" role="navigation">
      <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
        <span className="sr-only">Toggle navigation</span>
      </a>
      <div className="navbar-custom-menu">

      </div>
    </nav>
  </header>)
}

export default HeaderExternal;