import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from 'react-router-dom';
// import axios from 'axios';
import Axios from "../../utility/Axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configURL from "../../config/config";
import Layout from "../../layout/Layout";

import { useForm } from 'react-hook-form';

import InputError from "../../utility/InputError";
import RequiredStar from "../../utility/RequiredStar";
import ScreenLoader from "../../layout/ScreenLoader";

const EditMeetingVisitor = ({ history }) => {
    const [loader, setLoader] = useState(true);
    const [row, setRow] = useState({});
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const urlParams = useParams();

    useEffect(() => {
        retrieveData();
    }, []);

    const retrieveData = () => {
        setLoader(true);
        Axios({
            method: 'GET',
            url: `${configURL.Meeting.Visitors.View}${urlParams.invitation_id}`
        }).then(response => {
            setRow(response.data);
            setValue('substitute', response.data.substitute)
            setValue('is_present', response.data.is_present)
            setValue('is_late', response.data.is_late)
        }).finally(() => {
            setLoader(false);
        });
    };

    const handleUpdate = (data) => {
        let formData = {};
        formData['substitute'] = data.substitute || null;
        formData['is_present'] = data.is_present || false;
        formData['is_late'] = data.is_late || false;

        setLoader(true);

        Axios({
            method: 'POST',
            url: `${configURL.Meeting.Visitors.Update}${urlParams.invitation_id}`,
            data: formData
        }).then(response => {
            toast.success('Details updated!');
            history.push(`/meetings/visitors/add/${urlParams.meeting_id}`);
        }).catch(error => {
            toast.error('Something went wrong!')
        }).finally(() => {
            setLoader(false);
        });
    };

    const lForm = () => {
        return (<form onSubmit={handleSubmit(handleUpdate)} className="form-horizontal">
            <div className="box-body">
                <div className="form-group">
                    <label className="col-sm-2 control-label">Name</label>
                    <div className="col-sm-10">
                        <input name="name"
                            type="text"
                            disabled={true} defaultValue={row.name} className="form-control" placeholder="Name" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Email</label>
                    <div className="col-sm-10">
                        <input name="name" type="text" disabled={true} defaultValue={row.email} className="form-control" placeholder="Email" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Substitute</label>
                    <div className="col-sm-10">
                        <input name="name" type="text" {...register('substitute')} defaultValue={row.substitute} className="form-control" placeholder="Substitute" />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Is Present?</label>
                    <div className="col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" {...register('is_present')} />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-2 control-label">Is Late?</label>
                    <div className="col-sm-10">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" {...register('is_late')} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <div className="pull-right">
                    <button type="submit" className="btn btn-primary btn-block btn-flat">Submit</button>
                </div>
            </div>
        </form>)
    }

    return (
        <Layout>
            {loader && <ScreenLoader />}
            <div className="box box-danger">
                <div className="box-header with-border">
                    <ToastContainer />
                    <h3 className="box-title">
                        <span className="btn btn-success btn-xs btn-flat"><i className="fa fa-area-chart"></i></span>
                        Update Meeting Visitor
                    </h3>
                    <div class="pull-right box-tools">
                        <Link to={`/meetings/visitors/add/${urlParams.meeting_id}`} className="btn btn-primary btn-sm btn-flat"><i className="fa fa-arrow-left" title="Back"></i> Back to Meeting Visitors</Link>
                    </div>
                </div>
                {lForm()}
            </div>
        </Layout>
    )
}

export default EditMeetingVisitor;